Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";

exports.groupsApiEndPoint = "account_groups/groups";
exports.tasksListApiEndPoint = "tasks/task_lists";
exports.tasksApiEndPoint = "tasks/tasks";
exports.tasksAccountsApiEndPoint = "roles_permissions/accounts/list_users";

exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.selectTaskPriority = "Select task prority";
exports.selectTaskStatus = "Select task status";
exports.selectAccount = "Select account";
exports.selectGroup = "Select group";
exports.selectTasks = "Select tasks";
exports.textTitle = "Title";
exports.textDescription = "Description";
exports.dashboardOrder = "Dashboard";
exports.remindersOrder = "Reminders";
exports.worksOrderlabel = "Appointments";
exports.myAccountOrderabel = "landlordaccount";
exports.myAccountOrder = "My Account";
exports.mypropOrderlabel = "PortfolioManagement";
exports.workOrder = "Work Orders";
exports.reportOrderlabel = "VisualAnalytics";
exports.preferredOrder = "Preferred Vendors";
exports.electric = "Electric";
exports.mypropOrder = "My Properties";
exports.email = "Email";
exports.getCountriesCodesAPIEndPoint = "account_block/landlord_accounts/countries_listing";
exports.address = "Address";
exports.mobile = "Mobile: ";
exports.editDetails = "Edit Details";
exports.addressValue = "Please enter address.";
exports.phoneValue = "Please enter a valid phone number.";
exports.emailValue = "Please enter a valid email address.";
exports.exampleValue = "example@example.com";
exports.updateVendorApiEndPoint = "account_block/landlord_accounts/update_preferred_vendor";
exports.vendorsPhone = /^\d{10}$/;
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.cancel = "Cancel";
exports.submit = "Submit";
exports.insuranceLicensing = "State license";
exports.useOnlinePayables = "Use online payables";
exports.taxpayerName = "Taxpayer name";
exports.accountType = "Account type";
exports.areaCodes = "Area Code";
exports.expirationDate = "Other certificate";
exports.otherExpiration = "Other certificate expiration";
exports.stateInsurancExpirations = "State insurance expiration";
exports.stateInsurance = "State insurance";
exports.expirationsDate = "State license expiration";
exports.insurancesPaperWork = "EPA Certification expiration";
exports.contractExpiration = "EPA Certification license";
exports.workersComp = "Business license";
exports.companyName = "Company name";
exports.generalExpiration = "Business license expiration";
exports.ePAExpiration = "Business insurance";
exports.autoExpiration = "Business insurance expiration";
exports.updatedDate = "updated date";
exports.updatedBy = "updated by";
exports.accountConfirmation = "account confirmation";
exports.voidedCheckForBank = "Voided check for bank";
exports.lastPaymentPreference = "Last payment preference";
exports.paymentType = "Payment type";
exports.vendorBankAccountNumber = "Vendor bank account number";
exports.vendorBankRoutingNumber = "Vendor bank routing number";
exports.taxpayerID = "Taxpayer ID";
exports.taxFormAccountNumber = "Tax Form Account Number";
exports.send = "Send 1099?";
exports.formW = "Form W-9";
exports.portalActivated = "Portal activated?";
exports.viewWorkOrder = "View work order";
exports.noFound = "No work order found";
exports.downloads = "Download";
exports.phone = "Phone";
exports.details= "Details";
exports.vendorWorks = "Vendor works";
exports.clear = "Clear all";
exports.vendorStatus = "Vendor status";
exports.costValue = "$250";
exports.contactName = "Vendor name";
exports.financiaOrder = "Financial";
exports.filters= "Filters(";
exports.results = " results):";
exports.settingOrderlabel = "Settings2";
exports.reportOrder = "Delinquency Report";
exports.editVendor = "Edit vendor";
exports.vendorInfo = "Vendor information";
exports.chatOrder = "Chat with Keasy";
exports.modalTitle = "CG Lawn Care and Maintenance";
exports.settingOrder = "Settings";
exports.dashboardOrderlabel = "Dashboard";
exports.prevendorOrderlabel = "TaskList";
exports.reminOrderlabel = "AutomatedPrioritization";
exports.financOrderlabel = "Analytics";
exports.chatOrderlabel = "Chat";
exports.filterName = "Filters";
exports.onlinePayables = "Online Payables";
exports.compliance = "Compliance";
exports.addNewVendor = "Add new vendor";
exports.addVendor = "Add vendor";
exports.date = "Date";
exports.contact = "Contact";
exports.federalTax = "Federal Tax";
exports.uploadedby = "Uploaded by";
exports.vendorType = "Vendor type";
exports.vendorTrade = "Vendor trade";
exports.getShowVendorApiEndPoint = "account_block/landlord_accounts/show_all_preferred_vendors?search_query=";
exports.getVendorTypeApiEndPoint = "bx_block_categories/categories";
exports.getAllVendorsEntPoint = "account_block/vendor_accounts/list_all_vendors";
exports.postApprovedApiEndPoint = "account_block/landlord_accounts/assign_preferred_vendor";
exports.patchApiMethod = "PATCH";
exports.noAddFound = "No attachments found";
exports.slash = "-";
exports.shared = "Shared";
exports.countries = "Select a country";
exports.preferreNot = "Preferred vendor not found.";
exports.phoneNumbers = "Phone numbers";
exports.uploadDocuments = "Upload new document";
exports.renewal = "Renewal needed";
exports.actives = "Active";
exports.errorValue = "error";
exports.statusError = "A status has already been selected";
exports.searchError = "Search box must not exceed 30 characters."
exports.vendorError = "A vendor type has already been selected.";
exports.vendorSelected ="Select existing vendor";
exports.addExistingButton = "Add existing";
exports.edit = "Edit";
exports.notes = "Notes";
exports.attachments = "Attachments";
exports.textAdd = "Add";
exports.textTasks = "Tasks";
exports.textTaskList = "Task Lists";
exports.textEdit = "Edit";
exports.textDelete = "Delete";
exports.textSave = "Save";
exports.textClose = "Close";
exports.textAccounts = "Accounts";
exports.textGroups = "Groups";
exports.textAddTask = "Add Task";
exports.textAddTaskList = "Add Task List";
exports.textShowTaskList = "Show Task List";
exports.textShowTask = "Show Task";
exports.textId = "Id";
exports.textPriority = "Priority";
exports.textStatus = "Status";
exports.textActions = "Actions";
exports.textAssign = "Assign";
exports.textAssignedTo = "Assigned To";
exports.textName = "Name";
exports.textCreatedAt = "Created At";
exports.textUpdatedAt = "Updated At";
exports.textOr = "OR";
exports.vendorProfile  = "Vendor Profile";
exports.projects = "Project History";
exports.vendorLabelProfile = "UserProfileBasicBlock";
exports.vendorProjects = "Tasks";
exports.getWorkOrdersApiEndPoint = "/account_block/vendor_work_orders/";
exports.scheduleDateApiEndPoint = "/account_block/vendor_work_orders/scheduled_date";
exports.sendInvoiceApiEndPoint = "/account_block/vendor_work_orders/create_and_send_invoices";
// Customizable Area End
