import React from "react";

import {
  Box,
  Grid,
  Button,
  Typography,
  styled,

  // Customizable Area Start
  Menu,
  InputBase,
  Checkbox,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  ListItemText,
  MenuItem,
  ListItemIcon,
  Divider

  // Customizable Area End

} from "@mui/material";

// Customizable Area Start
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { KeyboardArrowUp, KeyboardArrowDown, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import {
  propertyMain, 
  activePropertyMain, 
  dashboard_g, 
  financial, 
  chatKey,
  activeOrderFinancial,
  Key_logo,
  plusIcon,
  filterIcon,
  searchIcon,
  activeDashboard,
  activeBell,
  bell,
  activeKey,
} from "./assets";
import { Category } from "./ServicerequestController";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

// Customizable Area End

import ServicerequestController, {
  Props,
  configJSON

} from "./ServicerequestController";

export default class Automatedworkflow extends ServicerequestController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCellsd key={property}>
        <Box display="flex" alignItems="center">
          {label}
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id={`sortUpId,${unitIndex}`}
           
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id={`sortDownId,${unitIndex}`}
           
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCellsd>
    );
  };
  screenProps = {
    navigation: this.props.navigation,
    id: "Analytics",
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <WorkOrderStylesd>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainNewrequestdash}
          sidebarItems={[
            { label: configJSON.dashboardLable, labelKey: configJSON.dashboardPath, icon: dashboard_g, activeIcon: activeDashboard, pathName: configJSON.dashboardPath },
            { label: configJSON.serviderequest, labelKey: configJSON.serviderequestPath, icon: bell, activeIcon: activeBell, pathName: configJSON.serviderequestPath },
            { label: configJSON.tenentlable, labelKey: configJSON.tenentBlock, icon:propertyMain , activeIcon: activePropertyMain, pathName: configJSON.tenentBlock },
            { label: configJSON.paymentLable, labelKey: configJSON.paymentBlock, icon:financial , activeIcon: activeOrderFinancial, pathName: configJSON.paymentBlock },
            { label: configJSON.chatLable, labelKey: configJSON.chatBlocK, icon:chatKey , activeIcon: activeKey, pathName: configJSON.chatBlocK }
          ]} 
          onClickSidebar={this.handleMainItemClicknewrequest}
          keasylogo={Key_logo}
          openDrawer={this.state.openTenentDrawersdash}
          onClickDrawer={this.handleTenentrequestDrawers} />
        </NavArea>
        
          <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
            sx={{
              padding: {
                flexGrow: 1,
                borderLeft: "1px solid rgb(208,203,199)",
              },
            }}>
            <MainWorkContainersd>
              <WorkStylessd>
                  <CustomNavbar 
                    {...this.screenProps}
                    showChatButton={true}   
                    showPropertiesButton={false} 
                  />
                  <WorkOrderHeadsd data-test-id="seeTextId">
                  Service Requests
                </WorkOrderHeadsd>
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <Box className="search-contents">
                      <img
                        className="search-icons"
                        src={searchIcon} alt="location" />
                      <InputBase
                        className="search-inputs"
                        placeholder="Search"
                        inputProps={{ "aria-label": "search" }}
                      />
                    </Box>
                  </Grid>
                  <Box sx={{display:"flex" , alignItems:"center",gap:"1rem",marginLeft:"1rem"}} >
                  <Select
                    data-test-id="description-block"
                    value={this.state.selectedCategoryId}
                    onChange={(event) =>
                      this.setState({ selectedCategoryId: event.target.value as number })
                    }
                    displayEmpty
                    renderValue={(selected) => {
                      const selectedCategory = this.state.allCatagory.find(
                        (cat) => cat.attributes.id === selected
                      );
                      return selectedCategory ? selectedCategory.attributes.name : <label>Category</label>;
                    }}
                    sx={{
                      height: "2.7rem",
                      backgroundColor: '#FFF1CC',
                      borderRadius: '8px',
                      border: 'none',
                      boxShadow: 'none',
                      '& .MuiSelect-select': {
                        display: 'flex',
                        alignItems: 'center',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                      '& label': {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: '#000',
                      },
                      '& .MuiSvgIcon-root': {
                        color: '#000',
                      },
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                          '& .MuiMenuItem-root': {
                            minHeight: 'auto',
                          },
                        },
                      },
                    }}
                  >
                  {this.state.allCatagory.map((cat: Category) => {
              return (
                <MenuItem key={cat.attributes.id} value={cat.attributes.id}>
                  {cat.attributes.name}
                </MenuItem>
              );
            })}
                  </Select>

                  <ScheduledNewWaiting  data-test-id="addServicebtn"  onClick={this.navigateToDashboard}>
                              <img
                                className="image-icon"
                                src={activeBell} alt="bill"
                                style={{ height: '20px', width: '20px' }} />
                              <ScheduledDate>Request a service</ScheduledDate>
                     
                      </ScheduledNewWaiting>
                      
                  </Box>
                  <Grid item md={6} xs={12}>
                    <Box className="filter-content">
                      <Box className="filter">
                        <img
                          className="icon"
                          src={filterIcon} alt="filter" />
                        <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                      </Box>
                      <Typography className="clear-data" data-test-id="clearOrderId">{configJSON.clearFilters}</Typography>
                    </Box>
                  </Grid>
                </Grid>
                <>
                  <React.Fragment>
                    <Grid container spacing={2} mt={2}>
                      <GridItemsd item xl={6} lg={6} md={6} sm={12}>
                        <HeadItemssd>
                          <HeadNamesd>Static Property Name|Unit Name</HeadNamesd>
                        </HeadItemssd>
                      </GridItemsd>
                      <GridItemsd item xl={6} lg={6} md={6} sm={12}>
                        <CompletedDetailssd>
                          <Box className="view-completed">
                            <VisibilityOutlinedIcon />
                            <Typography className="text-data">{configJSON.viewCompleted}</Typography>
                          </Box>
                        </CompletedDetailssd>
                      </GridItemsd>
                    </Grid>

                    <BorderContainersd>
                       <TableContainer >
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell padding="checkbox" sx={{color:"white"}}>
                                <Checkbox sx={{color:"white"}} />
                              </TableCell>
                              {this.renderSortableTableCell("id", configJSON.idNamesd, 1)}
                              <CustomTableCellsd>{configJSON.createdNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("category", configJSON.categoryNamesd, 1)}
                              <CustomTableCellsd>{configJSON.workNeedNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("assigned_vendor", configJSON.assignVendorNamesd, 1)}
                              {this.renderSortableTableCell("status", configJSON.statusNamesd, 1)}
                              <CustomTableCellsd>{configJSON.descriptionNamesd}</CustomTableCellsd>
                              {this.renderSortableTableCell("opend", "opend", 1)}
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
  
                        {this.state.serviceRequest && this.state.serviceRequest.map((request, index) => (
                          <React.Fragment key={index}>
                            <TableRow>
                              <TableCell padding="checkbox">
                                <CustomCheckbox
                                  style={{ color: 'white' }}
                                  data-test-id="checkedClickTestId"
                                />
                              </TableCell>
                              <CustomDetailTableCellsd data-test-id="rowId">
                                {request.attributes.id}
                              </CustomDetailTableCellsd>
                              <CustomDetailTableCellsd data-test-id="rowId">
                                Static 
                              </CustomDetailTableCellsd>
                              <CustomDetailTableCellsd>{request.attributes.category.category_name || "No Category"}</CustomDetailTableCellsd>
                              <CustomDetailTableCellsd>{request.attributes.work_needed}</CustomDetailTableCellsd>
                              <TableCell sx={{display:"flex",justifyContent:"center"}}>
                              <AssigneVendorCell>Static </AssigneVendorCell>
                              </TableCell>
                              <TableCell>
                              <StatusCell status={request.attributes.status}>{request.attributes.status}</StatusCell>
                              </TableCell>
                              <CustomDetailTableCellsd>{request.attributes.description}</CustomDetailTableCellsd>
                              <CustomDetailTableCellsd>{request.attributes.due_date_date}</CustomDetailTableCellsd>
                              <TableCell>
                                <MoreArrow>
                                  <IconButton data-test-id="expandClickTestId" onClick={() => this.handleExpandClick(index)}>
                                    {this.state.expanded === index ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                                  </IconButton>
                                </MoreArrow>
                              </TableCell>
                            </TableRow>

                            {this.state.expanded === index && (
                              <TableRow>
                                <TableCell colSpan={11}>
                                  <InnerTableBg>
                                    <OngoingName>{configJSON.goingOnName}</OngoingName>
                                    <Grid container spacing={2}>
                                      <Grid item xl={8} lg={8} sm={12} xs={12}>
                                        <TableContainer>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                {this.renderSortableTableCell("date", configJSON.dateName, 3)}
                                                {this.renderSortableTableCell("updated_by", configJSON.updatedName, 3)}
                                                <DetailsTableCellsd>{configJSON.detailsName}</DetailsTableCellsd>
                                                {this.renderSortableTableCell("status", configJSON.statusChangeName, 3)}
                                                <TableCell />
                                              </TableRow>
                                            </TableHead>
                                            <CustomTableContainer>
                                          
                                              {request.attributes.service_request_audits.map((audit, auditIndex) => (
                                                <React.Fragment key={auditIndex}>
                                                  <TableRow>
                                                    <StatusTableCell>{audit.date}</StatusTableCell>
                                                    <StatusTableCell>{audit.updated_by}</StatusTableCell>
                                                    <StatusTableCell>{audit.description}</StatusTableCell>
                                                    <StatusTableCell>{audit.status}</StatusTableCell>
                                                  </TableRow>
                                                </React.Fragment>
                                              ))}
                                            </CustomTableContainer>
                                            {request.attributes.service_request_audits.length === 0 && (
                                              <TableRow>
                                                <TableCell colSpan={11} align="center">
                                                    No audit log found
                                                </TableCell>
                                              </TableRow>
                                            )}
                                          </Table>
                                        </TableContainer>
                                      </Grid>
                                      <Grid item xl={4} lg={4} sm={12} xs={12}>
                                        <Images>{configJSON.imagesUpload}</Images>
                                        <Grid container alignItems={"center"} justifyContent={"center"} spacing={1}>
                                          <Grid item xl={6} lg={6} sm={6} xs={6}>
                                            <BulkUploadingFiles>
                                              <ImagesVideosFiles>
                                                <ImageFiles src="" alt={`Image ${index}`} />
                                              </ImagesVideosFiles>
                                            </BulkUploadingFiles>
                                          </Grid>
                                          <Typography textAlign={"center"} mt={8}>
                                            {configJSON.noImages}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </InnerTableBg>
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        ))}
                      </TableBody>
                             </Table>
                      </TableContainer>
                    </BorderContainersd>

                  </React.Fragment>

                </>
              </WorkStylessd>
            </MainWorkContainersd>
          
          </Box>
        </WorkOrderStylesd>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WorkOrderStylesd = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "&.webStyle": {
    backgroundColor: "red"
  },
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});

const MainWorkContainersd = styled(Box)({
  width: "90%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  marginLeft:"90px",
  "@media(max-width: 992px)": {
    marginLeft:"0px",
    padding: "0px 0px 0px 0px"

  }
});

const WorkOrderHeadsd = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily: "Outfit, sans-serif",
});

const WorkStylessd = styled(Box)({
  width: "90%",
  "& .search-main-content": {
    display: "flex",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer"
  }
});

const HeadNamesd = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});


const HeadItemssd = styled(Box)({
  display: "flex",
  gap: "10px"
});

const CompletedDetailssd = styled(Box)({
  display: "flex",
  gap: "30px",
  justifyContent: "flex-end",
  "@media(max-width: 460px)": {
    gap: "5px",
    width: "100%"
  }
});

const GridItemsd = styled(Grid)({
  width: "100%"
});

const BorderContainersd = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column",
  "& .scrollable-container": {
    height: "300px",
    overflowY: "auto"
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});

const CustomTableCellsd = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important"
});

const DetailsTableCellsd = styled(TableCell)({
  color: "#A3978F",
  fontSize: "12px",
  textAlign: "center",
  fontWeight: 700
});

const StatusCell = styled(TableCell)<{ status: string }>(({ status }) => ({
  fontFamily: "Outfit",
  width:"100%",
  fontWeight: 700,
  fontSize: "12px",
  padding:"0.5rem",
  color: status ===  "WAITING LANDLORD APPROVAL"? "#D97706" : status === configJSON.failed ? "FF0000" : "#FFA500",
  backgroundColor: status ===  "WAITING LANDLORD APPROVAL" ? "#FEF3C7" : status === configJSON.failed ? "#FFE5E5" : "#FFF3E0",
  borderRadius: "40px",
  textAlign: "center",
  textTransform: "capitalize",
  borderBottom:"none" 
}));
const CustomDetailTableCellsd = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  fontWeight: 400,
  alignContent:"center"
});
const AssigneVendorCell = styled(TableCell)({
  fontSize: "12px",
  color: "#A3978F",
  fontWeight: 400,
  alignContent:"center",
  borderBottom:"none",
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const OrderStatus = styled(Button)({
  borderRadius: "50px",
  backgroundColor: "#D1FAE5",
  color: "#059669",
  fontSize: "9px",
  width: "100%",
  borderColor: "#D1FAE5",
  fontWeight: 700,
  "&:hover": {
    backgroundColor: "#D1FAE5",
    color: "#059669",
    borderColor: "#D1FAE5"
  }
});

const DescriptionDetails = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#A3978F",
  wordBreak: "break-word"
});

const InnerTableBg = styled(Box)({
  border: "1px solid #F9FAFC",
  borderRadius: "10px",
  backgroundColor: "#F9FAFC",
  padding: "20px 0px 20px 20px"
});

const StatusTableCell = styled(TableCell)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  textAlign: "center"
});

const OngoingName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomTableContainer = styled(TableBody)({
  backgroundColor: "#FFFFFF"
});

const CustomCheckbox = styled(Checkbox)({
  color: "rgba(0, 0, 0, 0.54)",
  "&.Mui-checked": {
    color: "rgb(195,149,49)"
  },
  "&.MuiCheckbox-indeterminate": {
    color: "rgb(195,149,49)"
  }
});

const Images = styled(Typography)({
  textAlign: "left",
  fontSize: "16px",
  fontWeight: 700,
  color: "#1C1917"
});

const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});

const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: "20px",
  alignItems: "center",
  gap: "10px"
});

const ImagesVideosFiles = styled(Box)({
  height: "100px",
  width: "100px"
});

const ImageFiles = styled('img')({
  height: "100%",
  width: "100%",
  objectFit: "contain"
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const ScheduledNewWaiting = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap:"0.5rem", 
  width:"13rem",
  cursor: "pointer",
  justifyContent: "center",
});
const ScheduledDate = styled(Typography)({
  color: "#CC9200",
  fontFamily: 'Outfit', 
  fontSize: "15px",
  fontWeight: 700
});
// Customizable Area End

