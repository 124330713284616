import React from "react";

// Customizable Area Start
import SchedulingController, {Props, configJSON} from "./SchedulingController";
import {Box,styled, Typography,Button} from '@mui/material';
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { dashboardOrderLogo, activeOrderDashboard, accountsOrder, activeOrderAccount, ordersIcon, activeOrderIcon, vendorOrder, activeOrderVendor, remindersOrder, activeOrderReminder, propertyOrder, activePropertyOrder, financialOrder, activeOrderFinancial, reportOrder, activeOrderReport, keyOrderChain, activeKeyOrder, settingsOrder, activeOrderSetting, keasIcon, hexagon, halfHexagon} from '../../appointmentmanagement/src/assets';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import FullCalendar, { EventContentArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { keasy } from '../../automaticreminders/src/assets';
import moment from "moment";
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

function CustomDay(props: any) {
  const { day, outsideCurrentMonth, events, ...other } = props;
  const isEventDay = events.some((eventDate: string) =>
    dayjs(eventDate).isSame(day, 'day')
  );
  
  return (
    <Box position="relative">
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
      {isEventDay && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '5px',
            left: '50%',
            transform: 'translateX(-50%)',
            background: "#CC9200",
            height: "4px",
            width: "4px",
            borderRadius: "50%",
          }}
        />
      )}
    </Box>
  );
}

// Customizable Area End

export default class Scheduling extends SchedulingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
   // Customizable Area Start
   renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <Box
        data-test-id="btnEventInfo"
        style={webStyle.event}
        onClick={() => this.setEventInfo(eventInfo)}
      >
        <Typography style={webStyle.eventTitle}>
          {eventInfo.event.title}
        </Typography>
        <Typography style={webStyle.eventSubTitle}>
          {eventInfo.event.extendedProps.propertyName} | {eventInfo.event.extendedProps.unitName}
        </Typography>
        <Box style={webStyle.eventStatus}>
          <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(eventInfo.event.extendedProps.status)}>
            {eventInfo.event.extendedProps.status}
          </OrderStatus>
        </Box>
        <Typography style={webStyle.eventSubTitle}>
          {eventInfo.event.extendedProps.assignedTo}
        </Typography>
      </Box>
    );
  };
  

// Custom day rendering with event highlighting


  // Customizable Area End
  render() {
       // Customizable Area Start
      const events = this.mapCalendarDataToEvents(this.state.calendarData?.data);
      const highlightedDays = this.getHighlightedDates(this.state.calendarData?.data);
      
       // Customizable Area End
    return (
       // Customizable Area Start
      <SchedulingBasicStyle>
        <NavArea>
          <NavigationSidebar
            data-test-id={"navId"}
            activeItem={this.state.activeOrderItem}
            sidebarItems={[
              { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardOrderLogo, activeIcon: activeOrderDashboard, pathName: configJSON.dashboardOrderlabel },
              { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsOrder, activeIcon: activeOrderAccount, pathName: configJSON.myAccountOrderabel },
              { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersIcon, activeIcon: activeOrderIcon, pathName: configJSON.worksOrderlabel },
              { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorOrder, activeIcon: activeOrderVendor, pathName: configJSON.prevendorOrderlabel },
              { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersOrder, activeIcon: activeOrderReminder, pathName: configJSON.reminOrderlabel },
              { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyOrder, activeIcon: activePropertyOrder, pathName: configJSON.mypropOrderlabel },
              { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialOrder, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
              { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportOrder, activeIcon: activeOrderReport, pathName: configJSON.reportOrderlabel },
              { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyOrderChain, activeIcon: activeKeyOrder, pathName: configJSON.chatOrderlabel },
              { label: configJSON.settingOrder, labelKey: configJSON.workOrder, icon: settingsOrder, activeIcon: activeOrderSetting, pathName: configJSON.settingOrderlabel }
            ]}
            onClickSidebar={this.handleSideNav}
            keasylogo={keasIcon}
            openDrawer={this.state.openWorkOrder}
            onClickDrawer={this.handleWordDrawer}
          />
        </NavArea>
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
          <MainWorkContainer>
            <WorkStyles>
              
              <WorkOrderHead data-test-id="seeTextId">
                {configJSON.remindersOrder}
              </WorkOrderHead>
              <Box style={{display: "flex", justifyContent: "flex-end"}}>
                <Box style={{display: "flex", cursor: "pointer"}} onClick={this.navigateToNewReminder}>
                  <AddCircleOutlineOutlinedIcon style={{ color: '#CC9200',width: "16px", height: "16px" }}/>
                  <Typography style={{ color: '#CC9200', fontSize: "14px",fontWeight: 700, fontFamily: "Outfit",lineHeight: 1.2}}>Add Reminder</Typography>
                </Box>
              </Box>
              <Box className="outerCalender" style={{display: "flex", marginTop:"20px"}}>
                <Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box style={{width: "370px"}} sx={{
                      '& .MuiPickerStaticWrapper-root': {
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.3)', 
                        borderRadius: '8px', 
                      },
                      "& .css-10fao8b-MuiPickersCalendarHeader-labelContainer": {
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: 700,
                      },
                      "& .MuiPickersCalendarHeader-root": {
                        position: "relative",
                        width: "74%",
                        paddingLeft: "53px",
                      },
                      "& .MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon": {
                        display: "none",
                      },
                      "& PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition": {
                        minHeight: "205px",
                      },
                      "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
                        backgroundColor: "#FFF8E5",
                        border : "1px solid #FFE299",
                        color: "black",
                      },
                      "& .MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover": {
                        backgroundColor: "#FFE299",
                      },
                      "& .MuiPickerStaticWrapper-content":{
                        borderRadius: "8px",
                      },
                      "& .MuiCalendarOrClockPicker-root": {
                        display: "flex",
                        justifyContent: "center",
                      },
                      "& .MuiCalendarPicker-root": {
                        overflowX: "visible",
                        width: "360px",
                        maxHeight: "404px",
                        transform: 'scale(1.1)',
                        marginTop: "20px",
                      },
                      "& .PrivatePickersSlideTransition-root.MuiDayPicker-slideTransition":{
                        height: "220px",
                      },
                      "& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButtonbutton.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.MuiPickersCalendarHeader-switchViewButton": {
                        display: "none",
                      },
                      "& button.MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin.MuiPickersDay-today": {
                        backgroundColor: "#FFF8E5",
                        border : "1px solid #FFE299",
                        color: "black",
                      },
                      "& .css-epd502": {
                        overflowX: "visible",
                        margin: "5px",
                      },
                      "& .MuiPickersCalendarHeader-labelContainer":{
                        fontFamily: "Outfit",
                        fontWeight: 700,
                        fontSize: "14px",
                      },
                    }}>
                    <StaticDatePicker
                      data-test-id="selectDate"
                      displayStaticWrapperAs="desktop"
                      value={dayjs()}
                      onChange={(newValue) => this.setEventInfo(newValue)}
                      showDaysOutsideCurrentMonth  
                      renderDay={(day, _value, DayComponentProps) => (
                        <CustomDay {...DayComponentProps} day={day} events={highlightedDays}/>
                      )}
                      renderInput={() => <div></div>} 
                    />
                  </Box>
                </LocalizationProvider>
                <Box className="appointmentOuter">
                  <Typography style={{fontFamily: "Outfit",fontSize: "12px",fontWeight: 700,color: "#64748B",textTransform: "uppercase"}}>Upcoming Appointments</Typography>
                  <hr style={{width: "325px",border: "1px solid #E2E8F0", height:"0px"}}/>
                  <Box className="appointmentInner">
                  {(this.state.viewAll ? 
                    events.filter((event: any) => moment(event.start).isSameOrAfter(moment())) 
                    : 
                    events.filter((event: any) => moment(event.start).isSameOrAfter(moment())).slice(0, 3)
                  ).map((event: any) => (
                    <Box className="appointment" key={event.id}>
                      <Box>
                        <Typography style={{fontFamily:"Outfit",fontWeight: 700,fontSize:"16px"}}>{event.title}</Typography>
                        <Typography style={{fontFamily:"Outfit",fontSize:"14px"}}>{event.propertyName} | {event.unitName}</Typography>
                        <Typography style={{fontFamily:"Outfit",fontSize:"14px"}}>{event.assignedTo}</Typography>
                        <Typography style={{fontFamily:"Outfit",fontSize:"14px"}}>{moment(event.start).format('h:mmA')} - {moment(event.end).format('h:mmA')}, {moment(event.start).format('MMMM D')}</Typography>
                        <Box style={{display: "flex"}}>
                          <img src={keasy} alt="findKey" className="image-icon" />
                          <Typography style={{fontFamily:"Outfit",fontWeight: 700,fontSize:"12px",color: "#CC9200",lineHeight: 1.2}}>Let Keasy solve this for you</Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Box style={{display: "flex",justifyContent: "end"}}>
                          <img src={keasy} alt="findKey" className="image" />
                        </Box>
                        <OrderStatus data-test-id="statusId" sx={this.priorityStyleHandler(event.status)}>
                          {event.status}
                        </OrderStatus>
                      </Box>
                    </Box>
                  ))} 
                  </Box>
                  <Box style={{display: "flex", justifyContent: "end",fontSize: "16px"}}>
                  {events.length > 3 && (
                      <Button data-test-id="viewAll" onClick={() => this.handleViewAll()} style={{ textTransform: 'none', color: "#CC9200", fontFamily: "Outfit" }}>
                        {this.state.viewAll ? 'View Less' : 'View All'}
                      </Button>
                    )}
                  </Box>
                </Box>
                </Box>
                <Box className="calendar" style={{width: "100%",marginLeft: "40px",}}>
                  <FullCalendar
                      plugins={[dayGridPlugin,timeGridPlugin, interactionPlugin]}
                      initialView={this.state.currentView}
                      eventContent={this.renderEventContent}
                      events={events}
                      headerToolbar={{
                        end: "prev,next timeGridDay,timeGridWeek,dayGridMonth",
                      }}
                      titleFormat={{
                        month: 'short',  
                        year: 'numeric', 
                        day: 'numeric', 
                        weekday: 'long', 
                      }}
                      dayHeaders={false}
                      allDaySlot={false}
                      slotMinTime="07:00:00"  
                      slotMaxTime="21:00:00"
                      slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,  
                      }}
                      dayCellClassNames={() => 'custom-cell'}
                      nowIndicator={true}
                      now={new Date().toISOString()} 
                      timeZone="Local"
                    />
                </Box>
              </Box>
            </WorkStyles>
          </MainWorkContainer>
          <Box className="bgImgWrapper" data-test-id="bgImgId">
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-one" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-two" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={hexagon} alt="hexagon-three" className="hexImg" />
            </Box>
            <Box className="backgroundImage">
              <img src={halfHexagon} alt="half-hexagon" className="hexImg" />
            </Box>
          </Box>
         </WorkArea>
      </SchedulingBasicStyle>
       // Customizable Area Start
    );
    
  }
}
// Customizable Area Start 
const OrderStatus = styled(Typography)({
  borderRadius: "50px",
  color: "#059669",
  fontSize: "12px",
  textAlign: "center",
  whiteSpace: "nowrap",
  padding: "3px 10px",
  fontWeight: 700,
  fontFamily: "Outfit",
  textTransform: "uppercase",
});   
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  googleSigninButton: {
    backgroundColor: "#fff",
    alignItems: "center",
    color: "rgba(0, 0, 0, 0.54)",
    boxShadow:
      "rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px",
    borderRadius: 2,
    border: "1px solid transparent",
    fontSize: 14,
    fontWeight: 500,
    padding: 10,
    paddingLeft: 15,
    paddingRight: 15,
    marginTop: 50,
  },
  googleIcon: {
    height: 18,
    marginRight: 10,
  },
  calendarWrapper: {
    width: "100%",
    marginTop: 40,
  },
  event: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
    backgroundColor: "#fff",
    padding: "0px 20px 0px 20px",
    borderRadius: "6px",
    width: "100%",
    overflow: "hidden",
    cursor: "pointer",
  },
  eventTitle: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontWeight: 700,
    fontSize: 12,
    fontFamily: "Outfit",
  },
  eventSubTitle: {
    display: "flex",
    alignItems: "center",
    color: "black",
    fontSize: 12,
    fontFamily: "Outfit",
  },
  eventStatus: {
    display: "flex",
    alignItems: "center",
  },
  link: {
    color: "#3f51b5",
  },
  guestTitle: {
    marginTop: 20,
    marginBottom: 5,
  },
  attendeedWrapper: {
    display: "flex",
    flexDirection: "row" as "row",
    padding: 2,
  },
  attendeedIcon: {
    marginTop: 3,
    marginRight: 10,
  },
  textField: {
    marginBottom: 15,
  },
};
const SchedulingBasicStyle = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  fontFamily: "Outfit",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },  
  "& .bgImgWrapper": {
    display: "flex",
    justifyContent: "flex-end",
    width: "97%",
    marginTop: "2rem",
    fontFamily: "Outfit",
    "@media (max-width:1220px)": {
      width: "88%"
    },
    "@media (max-width:992px)": {
      display: "none",
    }
  },
  "& .backgroundImage": {
    width: "250px",
    "@media (max-width:1220px)": {
      width: "180px"
    },
  },
  "& .hexImg": {
    objectFit: "cover"
  }
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const WorkStyles = styled(Box)({
  width: "90%",
  marginTop: "42px",
  fontFamily: "Outfit",
  "& .fc table": {
    borderCollapse: "separate",
    borderSpacing: 0.1,
  },
  "& .fc .fc-timegrid-slot-minor": {
    borderTop: "none",
  },
  "& .fc .fc-timegrid-slot": {
    borderRight: "none",
    borderLeft: "none", 
  },
  "& .fc .fc-scrollgrid": {
    border: "none",
  },
  "& .fc-theme-standard td, .fc-theme-standard th": {
    borderRight: "none",
  },
  "& .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active" : {
    backgroundColor: "#FFD466",
    border: "1px solid #FFC123",
    color: "black",
    borderRadius: "50px",
    margin: "5px",
  },
  "& .fc .fc-button:focus": {
    boxShadow: "none",
  },
  "& .fc .fc-button-primary:focus": {
    boxShadow: "none",
  },
  "& .fc-button-primary:not(:disabled):active, .fc .fc-button-primary:not(:disabled).fc-button-active:focus": {
    boxShadow: "none",
  },
  "& .fc-button-group": {
    borderRadius: "50px",
    backgroundColor: "#F3F2F1",
  },
  "& .fc .fc-timegrid-slot-label-cushion": {
    fontSize: "12px",
    color: "#64748B",
  },
  "& .fc .fc-timegrid-slot-label": {
    borderTop: "none",
    verticalAlign: "top",
  },
  "& .fc .fc-button": {
    fontSize: "14px",
    fontFamily: "Outfit",
  },
  "& .fc-button-primary:hover": {
    backgroundColor: "#F3F2F1",
    border: "1px solid #F3F2F1",
    color: "black",
  },
  "& .fc .fc-timegrid-col.fc-day-today": {
    backgroundColor: "white",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
  },
  "& .fc .fc-timegrid-col": {
    borderRight: "1px solid rgb(221, 221, 221)",
  },
  " .fc-v-event": {
    border: "none",
    borderLeft: "4px solid #FFC123",
    backgroundColor: "white",
    boxShadow: "0 1px 12px rgba(0, 0.5, 0, 0.2)",
  },
  " .fc .fc-daygrid-event":{ 
    border: "none",
    borderLeft: "4px solid #FFC123",
    backgroundColor: "white",
    boxShadow: "0 1px 12px rgba(0, 0.5, 0, 0.2)",
  },
  "& .fc-button-primary": {
    color: "black",
    backgrounColor: "#F3F2F1",
  },
  "& .fc-button-primary::first-letter": {
    textTransform: "capitalize",
  },
  "& .fc-next-button":{
    border: "none",
    backgroundColor: "white",
    color: "#94A3B8",
  },
  "& .fc-prev-button":{
    border: "none",
    backgroundColor: "white",
    color: "#94A3B8",
  },
  "& .fc-next-button:hover, .fc-prev-button:hover": {
    border: "none",
    backgroundColor: "white",
  },
  "& .fc-now-indicator": {
    backgroundColor: "#CC9200 !important", 
    position: "relative",
  },
  "& .fc-now-indicator::before": {
    content: "''",
    width: "10px",
    height: "10px", 
    backgroundColor: "#CC9200",
    borderRadius: "50%", 
    position: "absolute",
    top: "50%", 
    left: "-15px", 
    transform: "translateY(-50%)", 
  },

  "& .fc-now-indicator-arrow": { 
    display: "none",
  },
  "& .fc-now-indicator-line": {
    backgroundColor: "#CC9200 !important",  
  },
  "& .fc .fc-daygrid-day": {
    border: "1px solid #ccc" 
  },
  "& .fc .fc-timegrid-axis": {
    border: "none", 
  },
  "& .fc-timeGridDay-button": {
    backgroundColor: "#F3F2F1",
    border: "1px solid #F3F2F1",
    borderTopLeftRadius: '50px', 
    borderBottomLeftRadius: '50px',
  },
  "& .fc-timeGridWeek-button": {
    backgroundColor: "#F3F2F1",
    border: "1px solid #F3F2F1",
  },
  "& .fc-dayGridMonth-button": {
    backgroundColor: "#F3F2F1",
    border: "1px solid #F3F2F1",
    borderTopRightRadius: '50px', 
    borderBottomRightRadius: '50px',
  },
  "& .fc .fc-toolbar-title": {
    fontSize: "20px",
    fontFamily: "Outfit",
  },
  "& .fc-button-active": {
    backgroundColor: "red",
  },
  "& .custom-cell": {
    padding: "10px",
    textAlign: "center",
    transition: "backgroundColor 0.3s",
  },
  
  "& .outerCalender": {
    display: "flex",
    gap:"10px",
  },
  "& .appointment": {
    width: "340px",
    display: "flex",
    justifyContent: "space-between",
    borderLeft: "4px solid #FFC123",
    borderRadius: "6px",
    boxShadow: "0 1px 12px rgba(0, 0.5, 0, 0.2)",
    padding: "15px",
    marginTop: "10px",
  },
  "& .appointmentOuter": {
    marginTop:"35px",
  },
  "& .appointmentInner":{
    width: "380px",
    height: "440px",
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "scrollbar-width": "none",
    "-ms-overflow-style": "none",
  },
  "& .image-icon": {
    width: "16px",
    height: "16px",
    marginRight: "5px",
  },
  "& .image": {
    height: "32px",
    width: "32px",
    marginBottom: "10px"
  }
});
const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontFamily: "Outfit",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px"
});
// Customizable Area End