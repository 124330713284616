import React from "react";

// Customizable Area Start
import { 
  Box,
  Typography ,
  styled, 
  Grid,
  InputBase,
  Button,
  Table,
  TableCell,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Chip,
  TextField,ButtonProps} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import {  filterIcon, searchIcon, keasyHelpIcon, messageIcon, eyeIcon, sendIcon, deleteImg, HomeImg, sendIcon1 } from './assets';
import {  ArrowDropDown, ArrowDropUp, MoreVert } from '@mui/icons-material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { PropertiesDetails } from "./VisualAnalyticsController";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import {getStatusStyles} from "./getStatusStyles"
export const keasyIcon = require("../../customform/assets/keasylogo.svg");
export const dashboardLandLogo = require("../../customform/assets/dashboard.png");
export const activeMainDashboard = require("../../customform/assets/activeDashboard.png");
export const accountsMain = require("../../customform/assets/profile.png");
export const activeMainAccount = require("../../customform/assets/activeProfile.png");
export const ordersMain = require("../../customform/assets/order.png");
export const activeMainOrder = require("../../customform/assets/activeorder.png");
export const vendorMain = require("../../customform/assets/vendor.png");
export const activeMainVendor = require("../../customform/assets/activeSave.png");
export const remindersMain = require("../../customform/assets/reminder.png");
export const activeMainReminder = require("../../customform/assets/activeReminder.png");
export const propertyMain = require("../../customform/assets/property.png");
export const activePropertyMain = require("../../customform/assets/activeProperty.png");
export const financialMain = require("../../customform/assets/financial.png");
export const activeMainFinancial = require("../../customform/assets/activeFinancial.png");
export const reportMain = require("../../customform/assets/report.png");
export const activeMainReport = require("../../customform/assets/activeReport.png");
export const keyMainChain = require("../../customform/assets/keyChain.png");
export const activeKeyMain = require("../../customform/assets/activeKey.png");
export const settingsMain = require("../../customform/assets/settings.png");
export const activeMainSetting = require("../../customform/assets/activeSetting.png");
export const projects = require("../../customform/assets/projects.png");
export const activeProjects = require("../../customform/assets/activeprojects.png");
export const prefferedVendor = require("../../customform/assets/PrefferedVendors.png");
export const activeOrderFinancial = require("../../customform/assets/activeFinancial.png");
import  CustomNavbar from "../../../components/src/CustomNavbar.web";
interface OrderStatusProps extends ButtonProps {
  status: string;
}
// Customizable Area End

import VisualAnalyticsController, {
  Props,
  configJSON,
} from "./VisualAnalyticsController";

export default class VisualAnalytics extends VisualAnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  screenProps = {
    navigation: this.props.navigation,
    id: "visualAnalytics",
  };
  renderSortableTableCell = (property: string, label: string, unitIndex: number) => {
    return (
      <CustomTableCell key={property}>
        <Box display="flex" justifyContent="center">
          <Typography fontSize="12px" fontWeight="700" style={{fontFamily: "Outfit",}}>{label}</Typography>
          <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
              data-test-id="sortUpId"
              onClick={() => this.handleSortRequest(property, "asc", unitIndex)}
            >
              <ArrowDropUp
              />
            </CustomIconButtonUp>
            <CustomIconButtonDown
              data-test-id="sortDownId"
              onClick={() => this.handleSortRequest(property, "desc", unitIndex)}
            >
              <ArrowDropDown
              />
            </CustomIconButtonDown>
          </Box>
        </Box>
      </CustomTableCell>
    );
  };
  renderChips = () => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: "1rem" ,marginTop:"1rem",marginBottom:"1rem"}} data-test-id="chip-filter-box" >
      {this.state.selectedProperties?.map((property: any) => (
        this.state.selectedUnits.filter((unit: any) => unit.propertyId === property.property_id))?.map((unit:any)=>(
          <Chip
            data-test-id="chip-block"
            key={unit.unit_id}
            label={`${this.formatPropertyName(property.property_name)} | ${this.formatPropertyName(unit.unitData.name)}`}
            onDelete={() => this.handleDelete(property,unit)}
            deleteIcon={<img src={deleteImg} alt="delete icon" style={{ width: '24px', height: '24px' }} />}
            sx={{
              display: "disply",
              flexDirection: 'row-reverse',
              borderRadius: '4px',
              border: '1px solid #000',
              backgroundColor: 'white',
              color: '#4A3F35',
              fontSize: '12px',
              fontWeight: 'bold',
              alignItems: 'center',
              justifyContent: 'space-between',
              '.MuiChip-deleteIcon': {
                color: '#4A3F35',
                marginLeft: '4px',
              },
              '.MuiChip-label': {
                padding: "0px 5px 0px 0px"
              }
            }}
          />
        ))
      )}
    </Box>
  )
  renderTanentdata = (selectedUnits: any,unit: any,unitIndex: any, property: any) => {
    return(
      (unit?.tenants ).map((data: any) => (                                    
        <React.Fragment  key={data.id}>
          <TableRow  key={data.id}>
            <TableCell>
              <OrderStatus variant="outlined" status={data.status.toLowerCase()}>
                {data.status}
              </OrderStatus>
            </TableCell>
            <CustomDetailTableCell>{data.name.charAt(0).toUpperCase() + data.name.slice(1)}</CustomDetailTableCell>
            <CustomDetailTableCell data-test-id="createTestId" style={{cursor: "pointer"}} onClick={() => this.handleClickOpendialog2(data.email_address)}>{ data.email_address.length > 15 ? `${data.email_address.charAt(0).toUpperCase() + data.email_address.slice(1).substring(0, 15)}...` : data.email_address.charAt(0).toUpperCase() + data.email_address.slice(1)}</CustomDetailTableCell>
            <CustomDetailTableCell>{data.phone_number}</CustomDetailTableCell>
            <CustomTableCellRed>{data.payment_0_to_30_days_late}</CustomTableCellRed>
            <CustomTableCellRed>{data.payment_over_0_to_30_days_late}</CustomTableCellRed>
            <CustomDetailTableCell data-test-id="rowId" onClick={() => this.handleClickOpendialog(data.id,data.comments,property, unit.name)}>
            <Box display="flex" alignItems="center" style={{cursor: 'pointer'}}>
              <img 
                src={data.comments.length > 0 ? eyeIcon : sendIcon} 
                alt={data.comments.length > 0 ? "See Comments Icon" : "Add Comment Icon"} 
                style={{ marginRight: "8px" }}
              />
                {data.comments.length > 0  ? `See (${data.comments.length}) comments` : "Add a comment"}
            </Box>
            </CustomDetailTableCell>
            <TableCell>
              <MoreArrow>
                <IconButton data-test-id="menuIconId" onClick={(menus) => { this.handleMenuIconClick(menus, data.id) }}>
                  <MoreVert />
                </IconButton>
                <Menu
                  data-test-id="menuIconCloseId"
                  anchorEl={this.state.anchorElement[data.id]}
                  keepMounted
                  open={Boolean(this.state.anchorElement[data.id])}
                  onClose={() => this.handleMenuClose(data.id)}
                  elevation={0}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                  }}
                  slotProps={{
                    paper: {
                      style: { marginLeft: "-8px", border: "1px solid #E2E8F0", padding: "0px 8px 0px 8px", borderRadius: "8px"}
                    }
                  }} 
                >  
                  <CustomNewMenuItem data-test-id="msgTest" onClick={() => this.handleClickOpendialog(data.id,data.comments,property, unit.name)}>
                    <ListItemIcon>
                      <img src={messageIcon} alt="editorIcon" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ 
                        sx: { fontSize: '14px' } 
                      }}  primary={"Send a message"} />
                  </CustomNewMenuItem>
                  <Divider />
                  <CustomNewMenuItem>
                    <ListItemIcon>
                      <img src={keasyHelpIcon} alt="editorIcon" />
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ 
                        sx: { fontSize: '14px' } 
                      }}  primary={"Ask Keasy for help"} />
                  </CustomNewMenuItem>
                </Menu>
              </MoreArrow>
            </TableCell>
          </TableRow>
        </React.Fragment>
      ))
    );}
  renderUnit = (selectedProperties: any,property: any, selectedUnits:any) => {
    return(
      (property.units)?.map((unit:any,unitIndex: number)=>(
      <BorderContainer key={unit.unit_id}>
        <OrderUnitContainer>
          <Box sx={{display:"flex", justifyContent: "flex-start", alignItems: "center", gap: "20px"}}>
          <OrderUnitName data-test-id='test'>{unit.name?.charAt(0).toUpperCase() + unit.name?.slice(1) || "NA"}</OrderUnitName>
          <OrderStatus variant="outlined" status={unit?.status.toLowerCase()}>{unit?.status}</OrderStatus>
          </Box>
          <Box data-test-id="gotoPropertyTest" onClick={this.goToProperties} sx={{cursor: "pointer"}}>
            <img src={HomeImg} alt="home"/>
            <span style={{color: "#CC9200", fontSize: "14px", fontWeight: 700, marginLeft: "10px"}}>Go to property</span>
          </Box>
        </OrderUnitContainer>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {this.renderSortableTableCell("tanentStatus", "Tenant status",unitIndex)}
                {this.renderSortableTableCell("name", "Name",unitIndex)}
                <CustomTableCell>Email</CustomTableCell>
                <CustomTableCell>Phone</CustomTableCell>
                {this.renderSortableTableCell("paymentLate0-30", "Payments 0-30 day late",unitIndex)}
                {this.renderSortableTableCell("paymentLateOver0-30", "Payments over 0-30 day late",unitIndex)}
                <CustomTableCell>Comments</CustomTableCell>
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody>  
              {this.renderTanentdata(selectedUnits,unit,unitIndex,property)}
              <TableRow data-test-id="noTantenttest" className={this.handleEmptyContainer(unit.tenants)}>
                <TableCell colSpan={11} align="center" style={{fontFamily: "Outfit",}}>
                  No Tenant found
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
    </BorderContainer> 
    )
    ))}
    renderTable = (selectedProperties: any,properties: any,selectedUnits: any) => {
      return(
        (properties)?.map((property:any)=> (
          <Box key={property.property_id}>
            <Grid container spacing={2} mt={2}>
            <GridItem item xl={6} lg={6} md={6} sm={12}>
              <HeadItems>
                <HeadName>{property.property_name.toUpperCase()}</HeadName>
                <MainHeadings>{property.address ? property.address.charAt(0).toUpperCase() + property.address.slice(1) : ''}</MainHeadings>
              </HeadItems>
            </GridItem>
            </Grid>
            {this.renderUnit(selectedProperties,property,selectedUnits)}
          </Box>
        )))
      }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <VisualAnalyticsStyle>
        <NavArea>
        <NavigationSidebar
          data-test-id={"navId"}
          activeItem={this.state.activeMainItem}
          sidebarItems={[
            { label: configJSON.dashboardOrder, labelKey: configJSON.dashboardOrderlabel, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.dashboardOrderlabel },
            { label: configJSON.myAccountOrder, labelKey: configJSON.settingOrderlabel, icon: accountsMain, activeIcon: activeMainAccount, pathName: configJSON.myAccountOrderabel },
            { label: configJSON.workOrder, labelKey: configJSON.worksOrderlabel, icon: ordersMain, activeIcon: activeMainOrder, pathName: configJSON.worksOrderlabel },
            { label: configJSON.preferredOrder, labelKey: configJSON.prevendorOrderlabel, icon: vendorMain, activeIcon: activeMainVendor, pathName: configJSON.prevendorOrderlabel },
            { label: configJSON.remindersOrder, labelKey: configJSON.reminOrderlabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.reminOrderlabel },
            { label: configJSON.mypropOrder, labelKey: configJSON.mypropOrderlabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.mypropOrderlabel },
            { label: configJSON.financiaOrder, labelKey: configJSON.financOrderlabel, icon: financialMain, activeIcon: activeOrderFinancial, pathName: configJSON.financOrderlabel },
            { label: configJSON.reportOrder, labelKey: configJSON.reportOrderlabel, icon: reportMain, activeIcon: activeMainReport, pathName: configJSON.reportOrderlabel },
            { label: configJSON.chatOrder, labelKey: configJSON.chatOrderlabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatOrderlabel },
            { label: configJSON.settingOrder, labelKey: configJSON.settings, icon: settingsMain, activeIcon: activeMainSetting, pathName: configJSON.settings }
          ]}
          onClickSidebar={this.handleMainItemClick}
          keasylogo={keasyIcon}
          openDrawer={this.state.openLandlordDrawer}
          onClickDrawer={this.handleLandlordDrawer}
        />
        </NavArea>
        <WorkArea display={"flex"} flexDirection={"column"} justifyContent={"space-between"} width={"100%"}
         >
        <MainWorkContainer>
        <WorkStyles>
          <CustomNavbar 
              {...this.screenProps}
              showChatButton={true}   
              showPropertiesButton={true} 
            />
          <WorkOrderHead data-test-id="seeTextId">
            Deliquency Report
          </WorkOrderHead>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <Box className="search-contents">
                  <img
                    className="search-icons"
                    src={searchIcon} alt="location" />
                  <InputBase
                    data-test-id="searchtest"
                    className="search-inputs"
                    placeholder="Search"
                    onChange={this.handleSearchChange}
                    inputProps={{ "aria-label": "search" }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} justifyContent="flex-start">
                <Box className="search-main-content">
                  <Button data-test-id="propertyClickId" className="properties-dropdown" onClick={this.handleProperClick}>
                    <Typography data-test-id="propertyNameId" className="text-data">{configJSON.propertiesHead}</Typography>
                    <KeyboardArrowDownIcon />
                  </Button>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box className="filter-content">
                  <Box style={{display: "flex"}}>
                    <Box className="filter">
                      <img
                        className="icon"
                        src={filterIcon} alt="filter" />
                      <Typography className="filter-data" data-test-id="headClickId">{configJSON.filtersHead}</Typography>
                    </Box>
                    {this.renderChips()}
                  </Box>
                  <Typography className="clear-data" data-test-id="clearOrderId" onClick={this.clearFilters}>{configJSON.clearFilters}</Typography>
                </Box>
              </Grid>
            </Grid>
            {this.state.properties.length > 0 ? this.renderTable(this.state.selectedProperties,this.state.properties,this.state.selectedUnits) : <Typography style={{marginTop: "30px", textAlign: "center"}}>No record found</Typography>}
            
            </WorkStyles>
            </MainWorkContainer>
            <Box style={{display: "flex",justifyContent: "flex-end",marginTop: "2.5rem",}}>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
              <Box style={{width: "250px"}}>
                <img src={require('../../appointmentmanagement/assets/hexagon.svg')}/>
              </Box>
            </Box>
        </WorkArea>
        <Menu
          data-test-id="menuId"
          id="property-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: -10, 
            horizontal: 'left',
          }}
          slotProps={{
            paper: {
              style: { width: "210px", border: "1px solid #E2E8F0", borderRadius: "8px", boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)" }
            }
          }}
        >
          {this.handlePropertyData().map((property: PropertiesDetails, propIndex: number) => (
            <CustomMenuItem
              data-test-id="propertyMenuId"
              key={property.property_name}
              onClick={(propevent) => this.handlePropertyClick(propevent, property, propIndex)}
              className={this.state.selectedMenuItemIndex === propIndex ? "changeColor" : ""}
            >
              <CustomListItemText primary={this.formatPropertyName(property.property_name)} />
              <KeyboardArrowRightIcon />
            </CustomMenuItem>
          ))}

          {this.handlePropertySlice() && (
            <CustomMenuItem
              data-test-id="seeAllId"
              key="seeAll"
              onClick={this.toggleShowAll}
            >
              <CustomListColor primary="See all" />
            </CustomMenuItem>
          )}
        </Menu>
        {this.state.selectedProperty && (
          <Menu
            data-test-id="menuItemId"
            anchorEl={this.state.subMenuAnchorEl}
            keepMounted
            open={Boolean(this.state.subMenuAnchorEl)}
            onClose={this.handleCloseMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            slotProps={{
              paper: {
                style: { width: "120px", marginLeft: "10px", border: "1px solid #E2E8F0", borderRadius: "8px"}
              }
            }}
          >
            {this.state.selectedProperty?.units.map((unit: any) => (
              <MenuItem
                data-test-id="unitMenuId"
                key={unit.name}
                onClick={() => this.handleUnitClick(this.state.selectedProperty?.property_id, unit)}
                selected={this.state.selectedUnits?.some((u:any) => u.propertyId === this.state.selectedProperty?.property_id && u.unitName === unit.name)}
                sx={{ textTransform: 'capitalize' }}
              >
                {this.formatPropertyName(unit.name) || "NA"}
              </MenuItem>
            ))}
          </Menu>
        )}
        <React.Fragment>
        
        <Dialog
          data-test-id="openDialog"
          open={this.state.isOpenDialog}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: '8px 8px 32px 8px',
              paddingBottom: "3rem",
            },
          }}
        >
           <DialogContent >
            <Box data-test-id="closeIcon" sx={{display: "flex", justifyContent: "right", alignItems: "right",cursor: 'pointer',marginBottom:"0.6rem"}} onClick={this.handleClosePopup}>
              <CloseIcon/>
            </Box>
            <Box>
              <Typography sx={{fontSize: "16px",fontWeight: 700}}>{this.state.selectedCommentProperty.toUpperCase()} <span style={{fontWeight: 400}}>{this.state.selectedCommentUnit}</span></Typography>
              <Divider sx={{borderBottom: "1px solid #000000", borderBottomWidth: "1px"}}/>
              <Typography sx={{fontSize: "16px", color: "#A3978F", marginTop: "1rem", cursor: "pointer"}} onClick={this.handleToggleComments}>{this.state.allComments.length>0 ? "Previous comments" : "" }</Typography>
              {this.state.allComments.slice(this.state.showAllComments ? 0 : -3).map((comment: any) => (
                <YellowBox>
                <Typography sx={{fontSize: "12px", color: "#A3978F",fontWeight: 700}}>{this.convertTime(comment.attributes.created_at)}</Typography>
                <Typography sx={{fontSize: "16px"}}>{comment.attributes.comment}</Typography>
              </YellowBox>
              ))}
              <CommentTextField
                label="New comment"
                multiline
                rows={4} 
                required
                inputProps={{ maxLength: 100 }}
                data-test-id="commentChangeTest"
                onChange={(e) => {
                  const value = e.target.value.trimStart();
                  this.handleCommentChange(value);
                }}
              />
              <SendButton style={{ textTransform: 'none' }} data-test-id="sendBtnTest" onClick={() => this.handleCommentSubmit(this.state.selectedTanentId)}>
                Send 
                <img style={{marginLeft: "10px"}} src={sendIcon1} />
              </SendButton>
            </Box>
          </DialogContent>

        </Dialog>
      </React.Fragment>
      <React.Fragment>
        
        <Dialog
          data-test-id="openDialog2"
          open={this.state.isOpenDialog2}
          onClose={this.handleCloseDialog2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              borderRadius: '8px 8px 32px 8px',
              maxWidth: "100%",
            },
          }}
        >
           <DialogContent >
            <Box data-test-id="closeIcon2" sx={{display: "flex", justifyContent: "right", alignItems: "right",cursor: 'pointer',marginBottom:"0.8rem"}} onClick={this.handleClosePopup2}>
              <CloseIcon/>
            </Box>
            <Box>
             <Typography>{this.state.email}</Typography>
            </Box>
          </DialogContent>

        </Dialog>
      </React.Fragment>
      </VisualAnalyticsStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const WorkStyles = styled(Box)({
  width: "90%",
  fontFamily: "Outfit",
  "& .search-main-content": {
    display: "flex",
    fontFamily: "Outfit",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    fontFamily: "Outfit",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    fontFamily: "Outfit",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%",
    fontFamily: "Outfit",
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    fontFamily: "Outfit",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
  "& .view-completed": {
    width: "180px",
    backgroundColor: "rgb(253,242,208)",
    height: "44px",
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    padding: "2px 10px 2px 10px",
    borderRadius: "8px",
    justifyContent: "space-around",
    cursor: "pointer",
    "@media(max-width: 460px)": {
      fontSize: "12px",
      height: "35px",
      width: "100%"
    },
    "@media(max-width: 347px)": {
      padding: "2px 5px 2px 5px"
    }
  },
  "& .icon": {
    mixBlendMode: "multiply"
  },
  "& .image-icon": {
    width: "16px",
    height: "16px"
  },
  "& .text-data": {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-data": {
    fontSize: "18px",
    color: "#CC9200",
    fontFamily: "Outfit",
    fontWeight: 700,
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .new-property": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    fontFamily: "Outfit",
    borderRadius: "8px",
    justifyContent: "space-around",
    paddingLeft: "5px",
    paddingRight: "5px",
    cursor: "pointer"
  },
  "& .filter-content": {
    display: "flex",
    fontFamily: "Outfit",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px",
    "@media(max-width: 992px)": {
    paddingRight: "0px",
    }
  },
  "& .filter": {
    display: "flex",
    fontFamily: "Outfit",
    alignItems: "center",
    gap: "15px",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000",
    fontFamily: "Outfit",
    paddingRight: "10px",
    "@media(max-width: 992px)": {
      paddingRight: "0px"
    }
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    fontFamily: "Outfit",
    whiteSpace: "nowrap",
    color: "#CC9200",
    cursor: "pointer",
    paddingLeft: "10px",
    "@media(max-width: 992px)": {
      paddingLeft: "0px"
    }
  },
  "& .isUnitsDisabled": {
    display: "none"
  }
});
const MainWorkContainer = styled(Box)({
  width: "100%",
  display: "flex",
  fontFamily: "Outfit",
  alignItems: "center",
  flexDirection: "column",
  "@media(max-width: 992px)": {
    padding: "0px 0px 0px 0px"

  }
});
const CommentTextField = styled(TextField)({
  width: "100%",
  borderRadius: "8px",
})

const YellowBox = styled(Box)({
  width: "100%",
  border: "1px solid #FFD466",
  borderRadius: "24px",
  padding: "10px 16px 10px 16px",
  margin: "1rem 0px 1rem 0px",
  boxSizing: 'border-box'
})

const GridItem = styled(Grid)({
  width: "100%"
});

const SendButton = styled(Button) ({
  width: "101px",
    height: "44px",
    fontWeight: 700,
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    color: "#000000", 
    fontSize: "16px",
    padding: "0px",
    letterSpacing: 0,
    cursor: "pointer",
    fontFamily: "Outfit, sans-serif",
    marginTop: "1rem",
    "&:hover": {
      backgroundColor: "#FFD700", 
    },
})

const OrderStatus = styled(Button)<OrderStatusProps>(({ status }) => ({
  borderRadius: "50px",
  fontSize: "9px",
  width: "100%",
  fontWeight: 700,
  fontFamily: "Outfit",
  "&:hover": {
    borderColor: getStatusStyles(status).borderColor,
    backgroundColor: getStatusStyles(status).backgroundColor,
    color: getStatusStyles(status).color,
  },
  ...getStatusStyles(status),
}));

const CustomDetailTableCell = styled(TableCell)({
  fontSize: "12px",
  color: "#1C1917",
  fontWeight: 400,
  width: '14.28%',
  textAlign: 'center',
});

const MoreArrow = styled(Box)({
  display: "flex"
});

const BorderContainer = styled(Box)({
  border: "1px solid #FFD466",
  borderRadius: "8px 8px 32px 8px",
  padding: "1rem 3rem 1rem 3rem",
  marginTop: "1rem",
  display: "flex",
  flexDirection: "column"
});

const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  fontWeight: 400,
  color: "#000000",
  margin: "0px 5px 0px 5px",
  "@media(max-width: 475px)": {
    fontSize: "10px",
  },
  "&.changeColor": {
    backgroundColor: "#f3f2f0",
    borderRadius: "8px"
  }
});

const CustomListColor = styled(ListItemText)({
  color: "#CC9200",
  fontSize: "14px",
  fontWeight: 400
});

const CustomListItemText = styled(ListItemText)({
  fontSiz: "14px",
  fontWeight: 400,
  "@media(max-width: 475px)": {
    width: "12px"
  }
});

const CustomNewMenuItem = styled(MenuItem)({
  fontSize: "12px",
  fontWeight: 400,
  color: "#000000",
  borderRadius: "8px",
  "&.changeColor": {
    backgroundColor: "rgb(250,227,162)",
    borderRadius: "8px"
  },
  "&:hover": {
    backgroundColor: "rgb(250,227,162)",
    color: "#000000"
  }
});

const OrderUnitContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "20px"
});

const OrderUnitName = styled(Typography)({
  minWidth: "60px",
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
});

const WorkOrderHead = styled(Typography)({
  fontSize: "24px",
  fontWeight: 700,
  color: "#000000",
  marginBottom: "20px",
  fontFamily: "Outfit",
});

const HeadName = styled(Typography)({
  fontSize: "16px",
  fontWeight: 700,
  color: "#000000",
  fontFamily: "Outfit",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const MainHeadings = styled(Typography)({
  fontSize: "16px",
  color: "#000000",
  fontWeight: 400,
  fontFamily: "Outfit",
  "@media(max-width: 405px)": {
    fontSize: "12px"
  }
});

const HeadItems = styled(Box)({
  display: "flex",
  gap: "10px"
});

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  width: '14.28%',
  fontFamily: "Outfit",
});

const CustomTableCellRed = styled(TableCell)({
  color: "#DC2626",
  textAlign: "center",
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  width: '14.28%',
  fontFamily: "Outfit",
});

const CustomIconButtonUp = styled(Typography)({
  marginBottom: "-14px",
  cursor: "pointer"
});

const CustomIconButtonDown = styled(Typography)({
  marginTop: "0px",
  cursor: "pointer"
});
const WorkArea = styled(Box)({
  marginLeft: "90px", 
  flexGrow: 1,
  overflowY: "auto",
  height: "100vh",
  "@media(max-width: 992px)": {
    marginLeft: "0px", 
    height: "auto",
    overflowY: "unset"
  }
});
const NavArea = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  height: "100%",
  overflowY: "auto",
  zIndex: 1000, 
  border: "1px solid rgb(208,203,199)",
  "&::-webkit-scrollbar": {
    display: "none"
  },
  "scrollbar-width": "none",
  "-ms-overflow-style": "none",
  "@media(max-width: 992px)": {
    position: "unset",
    height: "auto",
    overflowY: "hidden",
    border: "none",
  }
});
const VisualAnalyticsStyle = styled(Box)({
  display: "flex",
  fontFamily: "Outfit, sans-serif",
  width: "100%",
  justifyContent: "space-between",
  "@media(max-width: 992px)": {
    flexDirection: "column"
  },
  "& .isTanentDisabled": {
    display: "none"
  },
  "& .search-main-content": {
    display: "flex",
    gap: "1rem",
    "@media(max-width:600px)": {
      width: "100%",
      position: "relative",
      height: "100%",
      flexDirection: "row",
      display: "flex",
      "@media(max-width: 420px)": {
        gap: "5px"
      }
    },
  },
  "& .search-contents": {
    border: "1px solid #CBD5E1",
    borderRadius: "50px",
    height: "44px",
    display: "flex",
    paddingLeft: "15px",
    alignItems: "center",
    gap: "13px",
  },
  "& .search-icons": {
    height: "24px",
    width: "24px"
  },
  "& .search-text": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#A3978F",
    "@media(max-width: 420px)": {
      fontSize: "12px"
    }
  },
  "& .search-inputs": {
    width: "100%"
  },
  "& .filter-content": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "20px"
  },
  "& .filter": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    marginRight: "1rem",
  },
  "& .filter-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#000000"
  },
  "& .clear-data": {
    fontSize: "12px",
    fontWeight: 700,
    color: "#CC9200",
    cursor: "pointer",
    marginLeft: "1rem"
  },
  "& .properties-dropdown": {
    width: "142px",
    height: "44px",
    backgroundColor: "rgb(253,242,208)",
    display: "flex",
    alignItems: "center",
    textTransform: "none",
    borderRadius: "8px",
    padding: "2px 10px 2px 10px",
    justifyContent: "space-around",
    cursor: "pointer",
    color: "#000000",
    "&:hover": {
      backgroundColor: "rgb(253,242,208)",
      color: "#000000"
    },
    "@media(max-width: 420px)": {
      fontSize: "12px",
      width: "105px",
      height: "30px"
    }
  },
})
// Customizable Area End
