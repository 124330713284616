import React from "react";

import {
  Container,
  Box,
  Button,
  Typography,
  // Customizable Area Start
  Grid,

  FormControl, TextField, Radio, Checkbox, FormControlLabel, Divider, RadioGroup, MenuItem, Select, FormLabel, IconButton, Chip
  // Customizable Area End

} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import { CustomRadioButton } from "../../components/src/CustomRadioButton.web"
import EventIcon from '@mui/icons-material/Event';
import InputAdornment from '@mui/material/InputAdornment';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { styled } from '@mui/material/styles';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import DocumentsFileUpload from "../../components/src/DocumentsFileUpload.web";
import CustomDatePickerData from "../src/CustomDatePickerData.web";
import { downArrow } from "../../blocks/portfoliomanagement/src/assets";
import FileDataUpload from "../src/FileDataUpload.web";
import Close from "@mui/icons-material/Close";


const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 10px;
  resize: vertical,
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
 
  &:focus {
    border-color: #3f51b5;
    outline: none;
  }
`;

const StyledTextFieldthree = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    color: '#000000',
  },
  '& .MuiInputLabel-root': {
    color: '#BAB1AB',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#BAB1AB',
    },
    '&:hover fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#BAB1AB',
    },
    '&.MuiOutlinedInput-root':{
      borderRadius:"8px",
      height: "44px",
    },
  },
}));

interface FileWithPreview extends File {
  preview: string;
}
interface CustomEnroll1Props {
  handleChangePersonal: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChangetextArea: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  formData: {
    ongoingState: any;
    showplan: (event: React.ChangeEvent<HTMLInputElement>) => void;
    marketplan: (event: React.ChangeEvent<HTMLInputElement>) => void;
    loundryState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    alermEntry: (event: React.ChangeEvent<HTMLInputElement>) => void;
    unitEntry: (event: React.ChangeEvent<HTMLInputElement>) => void;
    coverWor: (event: React.ChangeEvent<HTMLInputElement>) => void;
    coverWorError: (event: React.ChangeEvent<HTMLInputElement>) => void;
    maximumState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    insuranceState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    mainState: string;
    whichoneState: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onlineState: string;

  }

  formErrors: {
    ongoingError: JSX.Element;
    showplanError: JSX.Element;
    marketplanError: JSX.Element;
    loundryError: JSX.Element;
    alermEntryError: JSX.Element;
    unitEntryError: JSX.Element;
    coverWorError: JSX.Element;
    mainStateError: JSX.Element;
    whichoneStateError: JSX.Element;
    insuranceStateError: JSX.Element;
    codeStateError: JSX.Element;
    entranceStateError: JSX.Element;
    floorStateError: JSX.Element;
    maximumError: JSX.Element;
    onlineStateError: JSX.Element;
    locationStateError: JSX.Element;
    checkError: string;
    attachmentStateError: JSX.Element



  };
  handleInsDateChange: () => void;
  insuranceDate: Date | null;
  handleattachmentChange: () => void;
  attachmentFile: FileWithPreview[];
  unitEntryPreAuthorized: string;
  whichOneData: string[];
  handleDelete: (name: string) => void;

  handleCheck: (event: React.ChangeEvent<HTMLInputElement>,index: number) => void;
}
const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: "18px" // Customize the font size here
  },
}));
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});




export default class CustomEnroll3 extends React.Component<CustomEnroll1Props> {

  render() {
    const { handleChangePersonal, formData, formErrors, handleSelectChangetextArea, handleSelectChange, handleInsDateChange, insuranceDate, handleattachmentChange, attachmentFile, unitEntryPreAuthorized, whichOneData, handleDelete, handleCheck} = this.props;
    function DividerEnrolfirst3() {
      return (
        <>
          <Grid container justifyContent="flex-end" sx={{ marginTop: "3rem" }}>
            <Grid item md={12}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', color: "red" }}>
                <Divider sx={{ width: '100%' }} />
              </Box>
            </Grid>
          </Grid>
        </>
      )
    }
    const whichOneDatas = ["Property 1", "Property 2", "Property 3"];
    return (

      <Box style={{ display: "flex" }}>



        <Grid container style={{ padding: "2rem" }} >
          <Grid item md={3} xs={12}>
            <Box  >
              <Typography style={webStyle.keyssectiontext}>Maintenance <br /> Information</Typography>
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>

            <Box>

              <label style={webStyle.pettextchange}>Maximum approved maintenance</label>
              <StyledTextFieldthree
                data-testId="maximum-approved"
                variant="outlined"
                placeholder="0.00"
                value={formData.maximumState}
                onChange={handleChangePersonal}
                name="maximumState"
                fullWidth
                sx={webStyle.enroll3manage}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            {formErrors.maximumError && (
              <span style={webStyle.ErrorStylelandthree}>{formErrors.maximumError}</span>
            )}
            <Box style={webStyle.marginBorderstyle3}>
              <label style={webStyle.bcforguytext}>Insurance expiration</label>
               <CustomDatePickerData
                  data-testId="insurance-field"
                  value={insuranceDate}
                  required={false}
                  onChange={handleInsDateChange} 
                />
            </Box>
            {formErrors.insuranceStateError && (
              <span style={webStyle.ErrorStylelandthree}>{formErrors.insuranceStateError}</span>
            )}

            <Grid container>
              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Has home warranty coverage?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>

                    <FormControl component="fieldset">

                      <RadioGroup value={formData.coverWor} name="coverWor" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          label={
                            <Typography 
                                fontFamily = "Outfit"
                                fontSize = "16px" 
                                variant = "body1" 
                            >
                                Yes
                            </Typography>
                        } 
                        control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          value="0"
                          label={
                            <Typography 
                                fontFamily = "Outfit"
                                fontSize = "16px" 
                                variant = "body1" 
                            >
                                No
                            </Typography>
                        } 
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.coverWorError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.coverWorError}</span>
                  )}
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Unit entry pre-authorized</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>


                    <FormControl component="fieldset">

                      <RadioGroup value={formData.unitEntry} name="unitEntry" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          control={<CustomRadioButton />}
                          label={
                            <Typography 
                                fontFamily = "Outfit "
                                variant = "body1" 
                                fontSize = "16px " 
                            >
                                Yes
                            </Typography>
                        } 
                        />
                        <FormControlLabel
                          value="0"
                          control={<CustomRadioButton />}
                          label={
                            <Typography 
                                fontFamily = "Outfit "
                                variant = "body1" 
                                fontSize = "16px " 
                            >
                                No
                            </Typography>
                        } 
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.unitEntryError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.unitEntryError}</span>
                  )}
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Does the property has fire alarm system?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>


                    <FormControl component="fieldset">

                      <RadioGroup value={formData.alermEntry} name="alermEntry" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          label={
                            <Typography
                              variant="body1"
                              fontFamily=" Outfit"
                              fontSize=" 16px"
                            >
                              Yes
                            </Typography>
                          } 
                          value="1"
                          control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          value="0"
                          label={
                            <Typography 
                                fontFamily = " Outfit"
                                variant = "body1" 
                                fontSize = " 16px" 
                            >
                                No
                            </Typography>
                        } 
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.alermEntryError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.alermEntryError}</span>
                  )}
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Is there a common laundry room on site?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>
                     <FormControl component="fieldset">
                       <RadioGroup value={formData.loundryState} name="loundryState" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          label={
                            <Typography 
                                fontFamily="Outfit "
                                variant="body1" 
                                fontSize="16px " 
                            >
                                Yes
                            </Typography>
                        } 
                          control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          value="0"
                          label={
                            <Typography
                              fontFamily="Outfit "
                              fontSize="16px "
                              variant="body1"
                            >
                              No
                            </Typography>
                          } 
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.loundryError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.loundryError}</span>
                  )}
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Is there a marketing plan?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>



                    <FormControl component="fieldset">

                      <RadioGroup value={formData.marketplan} name="marketplan" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          label={
                            <Typography
                              variant="body1"
                              fontFamily=" Outfit"
                              fontSize=" 16px"
                            >
                              Yes
                            </Typography>
                          } 
                          control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          label={
                            <Typography
                              fontFamily="Outfit"
                              fontSize="16px"
                              variant="body1"
                            >
                              No
                            </Typography>
                          } 
                          value="0"
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.marketplanError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.marketplanError}</span>
                  )}

                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Is there a snow plan?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>



                    <FormControl component="fieldset">

                      <RadioGroup value={formData.showplan} name="showplan" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          label={
                            <Typography
                              fontSize="16px "
                              fontFamily= "Outfit "
                              variant="body1"
                            >
                              Yes
                            </Typography>
                          } 
                          control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          value="0"
                          label={
                            <Typography 
                                fontFamily="Outfit"
                                fontSize = "16px " 
                                variant = "body1" 
                            >
                                No
                            </Typography>
                        } 
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.showplanError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.showplanError}</span>
                  )}
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <Box style={webStyle.marginBorderstyle3}>
                  <Box>
                    <label style={webStyle.bcforguytext}>Is there any other ongoing maintenance you would like Keasy to monitor?</label>
                  </Box>
                  <Box style={webStyle.marginBorderstyle3}>



                    <FormControl component="fieldset">

                      <RadioGroup value={formData.ongoingState} name="ongoingState" onChange={handleChangePersonal} style={{ display: "flex", flexDirection: "row" }}>
                        <FormControlLabel
                          value="1"
                          label={
                            <Typography 
                                fontFamily ="Outfit "
                                fontSize="16px" 
                                variant ="body1" 
                            >
                                Yes
                            </Typography>
                        } 
                          control={<CustomRadioButton />}
                        />
                        <FormControlLabel
                          value="0"
                          label={
                            <Typography
                              fontSize= "16px "
                              fontFamily="Outfi"
                              variant="body1"
                            >
                              No
                            </Typography>
                          } 
                          control={<CustomRadioButton />}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  {formErrors.ongoingError && (
                    <span style={webStyle.ErrorStylelandthree}>{formErrors.ongoingError}</span>
                  )}
                </Box>
              </Grid>
            </Grid>
            <Box style={{ borderRadius: '8px', position: 'relative', marginTop:"20px" }}>
              <label style={webStyle.bcforguytext}>Which one?</label>
              <StyledTextFieldthree
                data-testId="which-one"
                select
                fullWidth
                name="whichoneState"
                InputLabelProps={{ shrink: false }}
                value={formData.whichoneState}
                onChange={handleSelectChange}
                style={{ paddingRight: '30px'}} 
              >
                {whichOneDatas.map((propData, index) => (
                    <MenuItem key={index} value={propData}>{propData}</MenuItem>
                  ))}
              </StyledTextFieldthree>
              <img
                style={{
                  position: 'absolute',
                  right: '13px',
                  top: '70%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                }}
                src={downArrow}
                alt="down"
              />
            </Box>
            <Box mt={2}>
              {whichOneData.map((trade:string, index :number) => (
                <Chip
                  key={index}
                  label={trade}
                  variant="outlined"
                  icon={
                    <IconButton
                      aria-label="delete"
                      data-test-id="deleteIconButton"
                      size="small"
                     onClick={() => handleDelete(trade)}
                      sx={{ mr: -1 }}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  }
                  sx={{ mr: 1, mb: 1, border: 2, borderRadius: 1 }}
                />
              ))}
            </Box>
            {formErrors.whichoneStateError && (
              <span style={webStyle.ErrorStylelandthree}>{formErrors.whichoneStateError}</span>
            )}


            <Box style={webStyle.marginBorderstyle3}>

              <label style={webStyle.pettextchange}>Maintenance notes</label>
              <StyledTextarea
                data-testId="maintenance-note"
                sx={webStyle.textstyleArea}
                minRows={3}
                placeholder=""
                value={formData.mainState}
                data-test-id="Maintenancenotes"
                name="mainState"
                onChange={handleSelectChangetextArea}
              />
              <Typography sx={webStyle.floatsText}>
                {formData.mainState.length}/100
              </Typography>
            </Box>
            {formErrors.mainStateError && (
              <span style={webStyle.ErrorStylelandthree}>{formErrors.mainStateError}</span>
            )}

            <Box style={webStyle.marginBorderstyle3}>

              <label style={webStyle.pettextchange}>Online maintenance request instructions</label>
              <StyledTextarea
                data-testId="online-maintenance"
                sx={webStyle.textstyleArea}
                minRows={3}
                placeholder="e.g. In case of water leaks, do not file a maintenance request, call (888) 555-5555."
                value={formData.onlineState}
                data-test-id="Maintenancenotes"
                name="onlineState"
                onChange={handleSelectChangetextArea}
              />
              <Typography sx={webStyle.floatsText}>
                {formData.onlineState.length}/100
              </Typography>
            </Box>
            {formErrors.onlineStateError && (
              <span style={webStyle.ErrorStylelandthree}>{formErrors.onlineStateError}</span>
            )}


          </Grid>

          <DividerEnrolfirst3 />

          <Grid container style={webStyle.marginBorderstyle3} >
            <Grid item md={3} xs={12}>
              <Box  >
                <Typography style={webStyle.keyssectiontext}>Attachments</Typography>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Box>

                <FileDataUpload
                  label=""
                  name="formW9Files"
                  files={attachmentFile}
                  onFilesChange={handleattachmentChange}
                  onCheckboxChange={(event,index)=>handleCheck(event,index)}
                  />
              </Box>
              {formErrors.attachmentStateError && (
              <span style={webStyle.ErrorStyleland}>{formErrors.attachmentStateError}</span>
            )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  ErrorStylelandthree: {
    color: 'rgb(211, 47, 47)', fontFamily: "Outfit", fontWeight: 400, fontSize: "0.75rem"
  },
  enroll3manage: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      marginTop: "0.5rem",
      height: "44px"
      // 8px border-radius
    }
  },
  colorradio: {
    color: "#FFC123"
  },
  textstyleArea: {
    height: "132px", marginTop: "0.6rem",
    maxHeight: "132px",
    resize: "vertical",
    minHeight: "132px"
  },
  flexradio: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "1rem"
  },
  marginBorderstyle3: {
    borderRadius: "8px", marginTop: "1rem"
  },
  marginBorderstyle2: {
    borderRadius: "8px", marginTop: "1rem"
  },

  pettextchange: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "4px",
    fontFamily: "Outfit",
    color: "#544B45"
  },
  floatsText: {
    color: "#64748B",
    textAlign: "end",
    fontFamily: "Outfit",
    borderRadius: "8px",
    fontSize: "12px",
    fontWeight: 400,
    "@media (max-width:1000px)": {
      width: "100%",
    }
  },
  keyssectiontext: {
    fontSize: "18px",
    fontWeight: 700,
    lineHeight: "17px",
    fontFamily: "Outfit",
    color: "#BAB1AB"

  },
  addstyle: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: '#CC9200'

  },
  bcforguytext: {
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    fontFamily: "Outfit",
    color: "#544B45"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    marginTop: 20,
    marginBottom: 20,
  },
  fileWrapper: {
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  fileName: {
    flex: 1,
  },
  submitBtnStyle: {
    marginTop: 20,
  },
  uploadBtnWrapper: {
    display: "flex",
    marginTop: 20,
  },
  uploadBtn: {
    padding: 7,
    paddingLeft: 15,
    paddingRight: 15,
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 15,
  },
  clearBtnStyle: {
    marginRight: 15,
  },
  bulkFileWrapper: {
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 5,
    marginBottom: 20,
  },
  bulkFileTopWrapper: {
    display: "flex",
    alignItems: "center",
  },
  textWrapper: {
    flex: 1,
  },
  fileItemWrapper: {
    display: "flex",
    border: "1px solid #ccc",
    padding: 10,
    marginTop: 15,
    borderRadius: 5,
    alignItems: "center",
  },
  ErrorStyleland: {
    color: 'rgb(211, 47, 47)', fontFamily: "outfit", fontWeight: 400, fontSize: "0.75rem"
  },
};
// Customizable Area End
