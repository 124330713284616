import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { AlertColor } from "@mui/material";
import { FormEvent } from "react";

interface FileWithPreview extends File {
  preview: string;
}

interface WorkOrderAudit {
  date: string;
  updated_by: string ;
  description: string | null;
  status: string;
}

export interface WorkOrder {
  id: number;
  date: string;
  requested_by: string;
  property: string;
  category: string;
  work_needed: string;
  description: string;
  cost: string;
  status: string;
  work_order_audits: WorkOrderAudit[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  activeVendorItem: string;
  openVendorDrawer: boolean;
  startDate: Date | null;
  endDate: Date | null;
  searchInput: string;
  searchArray: string[];
  workOrders: WorkOrder[];
  selectedOrders: number[];
  sendInvoiceModal: boolean;
  scheduleDateModal: boolean;
  modalWorkOrderArray: number[];
  currentWorkOrderIndex: number;
  currentModalWorkOrder: any;
  invoiceDate: Date | null;
  dueDate: Date | null;
  price: string;
  taxes: string;
  receipts: FileWithPreview[];
  errors: {
    price: boolean;
    taxes: boolean;
    receipts: boolean;
  }
  openDetailRow: number | null;
  anchorNewElement: { [key: number]: HTMLElement | null };
  currentPage: number;
  rowsPerPage: number;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  // Customizable Area End
}

export interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class TasksController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWorkOrdersApiCallId: string = "";
  scheduleDateApiCallId: string = "";
  sendInvoiceApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeVendorItem: "Tasks",
      openVendorDrawer: false,
      startDate: null,
      endDate: null,
      searchInput: "",
      searchArray: [],
      workOrders: [
        {
          id: 58526,
          date: "Jan 08 2024",
          requested_by: "Mattie Blooman",
          property: "12439 BLV - Unit 1",
          category: "Gardening",
          work_needed: "Prune shrubs",
          description: "Next to the entrance",
          cost: "2000",
          status: "completed",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58527,
          date: "Jan 09 2024",
          requested_by: "Attie Blooman",
          property: "12430 BLV - Unit 1",
          category: "Plumbing",
          work_needed: "Geyser fitting",
          description: "Next to the washroom",
          cost: "1500",
          status: "awaiting",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58528,
          date: "Jan 19 2023",
          requested_by: "Lattie Blooman",
          property: "BLV - Unit 1",
          category: "Electrician",
          work_needed: "Chandelier fitting",
          description: "Inside hall",
          cost: "3000",
          status: "paid",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58529,
          date: "Jan 28 2024",
          requested_by: "Mattie Flooman",
          property: "1231 BLV - Unit 1",
          category: "General",
          work_needed: "Dog walk",
          description: "In the park",
          cost: "1000",
          status: "progress",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58530,
          date: "Jan 08 2024",
          requested_by: "Mattie Blooman",
          property: "12439 BLV - Unit 1",
          category: "Gardening",
          work_needed: "Prune shrubs",
          description: "Next to the entrance",
          cost: "2000",
          status: "completed",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58531,
          date: "Jan 09 2024",
          requested_by: "Attie Blooman",
          property: "12430 BLV - Unit 1",
          category: "Plumbing",
          work_needed: "Geyser fitting",
          description: "Next to the washroom",
          cost: "1500",
          status: "awaiting",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58532,
          date: "Jan 19 2023",
          requested_by: "Lattie Blooman",
          property: "BLV - Unit 1",
          category: "Electrician",
          work_needed: "Chandelier fitting",
          description: "Inside hall",
          cost: "3000",
          status: "paid",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
        {
          id: 58533,
          date: "Jan 28 2024",
          requested_by: "Mattie Flooman",
          property: "1231 BLV - Unit 1",
          category: "General",
          work_needed: "Dog walk",
          description: "In the park",
          cost: "1000",
          status: "progress",
          work_order_audits: [
            {
              "date": "20 Aug 2024 | 06:38 AM",
              "updated_by": "landlord ",
              "description": null,
              "status": "Work in progress"
            }
          ]
        },
      ],
      selectedOrders: [],
      sendInvoiceModal: false,
      scheduleDateModal: false,
      modalWorkOrderArray: [],
      currentWorkOrderIndex: 0,
      currentModalWorkOrder: {},
      invoiceDate: null,
      dueDate: null,
      price: "",
      taxes: "",
      receipts: [],
      errors: {
        price: false,
        taxes: false,
        receipts: false,
      },
      openDetailRow: null,
      anchorNewElement: {},
      currentPage: 1,
      rowsPerPage: 5,
      messageOpen: false,
      messageType: "success",
      message: "",
      // Customizable Area End
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.getWorkOrders();
  }

  navigateToTaskList = () => {
    this.props.navigation.navigate("TaskList");
  };

  navigateToTask = () => {
    this.props.navigation.navigate("Task");
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleGetWorkOrdersResponse(message);
      this.handleScheduleDateResponse(message);
      this.handleSendInvoiceResponse(message);
    }
  };

  getWorkOrders = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getWorkOrdersApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWorkOrdersApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  onSendInvoiceSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.validateForm()) {
      const header = {
        token: localStorage.getItem("authToken"),
      };
  
      const formData = new FormData();
  
      formData.append(
        "invoice[vendor_work_order_ids][]",
        this.state.currentModalWorkOrder.id
      );
  
      formData.append(
        "invoice[ledger_type]",
        "vendor payment"
      );
  
      formData.append(
        "invoice[invoice_date]",
        this.state.invoiceDate ? this.state.invoiceDate.toISOString().split("T")[0] : ""
      );
  
      formData.append(
        "invoice[due_date]",
        this.state.startDate ? this.state.startDate.toISOString().split("T")[0] : ""
      );
  
      formData.append(
        "invoice[price]",
        this.state.price
      );
  
      formData.append(
        "invoice[taxes]",
        this.state.taxes
      );
  
      if(this.state.receipts.length) {
        this.state.receipts.forEach((file) => {
          formData.append("invoice[receipts][]", file as Blob);
        });
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.sendInvoiceApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.sendInvoiceApiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  onScheduleDateSubmit = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };

    const formData = new FormData();

    formData.append(
      "vendor_work_order[vendor_work_order_ids][]",
      this.state.currentModalWorkOrder.id
    );

    formData.append(
      "vendor_work_order[start_date]",
      this.state.startDate ? this.state.startDate.toISOString().split("T")[0] : ""
    );

    formData.append(
      "vendor_work_order[end_date]",
      this.state.endDate ? this.state.endDate.toISOString().split("T")[0] : ""
    );

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.scheduleDateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.scheduleDateApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetWorkOrdersResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getWorkOrdersApiCallId && responseJson?.data?.length) {
      let workOrders: WorkOrder[] = responseJson.data.map((order: any) => {
        return {
          id: order.attributes.id,
          date: this.formatDate(order.attributes.created_at),
          requested_by: "confirmation",
          property: order.attributes.property.property_name + " - " + order.attributes.unit.name,
          category: "confirmation",
          work_needed: order.attributes.work_needed,
          description: order.attributes.description ?? "",
          cost: order.attributes.cost ?? "--",
          status: order.attributes.status,
          work_order_audits: order.attributes.work_order_audits
        };
      });
      this.setState({ workOrders: workOrders });
    } else {
      if (responseJson?.errors && responseJson?.errors[0].token) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: "Session Expired, Please Log in again."
        });
      } else {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson?.errors
        });
      }
    }
  };

  handleSendInvoiceResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.sendInvoiceApiCallId && responseJson?.data) {
      this.setState({
        messageOpen: true,
        messageType: "success",
        message: "Form submitted successfully."
      });
      setTimeout(() => {
        this.setState({ sendInvoiceModal: false });
      }, 2000);
    } else {
      if (responseJson?.errors && responseJson?.errors[0].token) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: "Session Expired, Please Log in again."
        });
      } else {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson?.errors
        });
      }
    }
  };

  handleScheduleDateResponse = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.scheduleDateApiCallId && responseJson?.data) {
      this.setState({
        messageOpen: true,
        messageType: "success",
        message: "Form submitted successfully."
      });
      setTimeout(() => {
        this.setState({ scheduleDateModal: false });
      }, 2000);
    } else {
      if (responseJson?.errors && responseJson?.errors[0].token) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: "Session Expired, Please Log in again."
        });
      } else {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson?.errors
        });
      }
    }
  };

  onVendoritemClick = (vendorKey: string) => {
    this.setState({ activeVendorItem: vendorKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  onVendorDrawer = () => {
      this.setState({
          openVendorDrawer: !this.state.openVendorDrawer
      });
  };

  handleWorkOrderMenu = () => {
    const { activeVendorItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeVendorItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };

  formatDate = (dateString: string) => {
    if(dateString){
      const dateObject = new Date(dateString);

      const day = String(dateObject.getUTCDate()).padStart(2, '0');
      const month = String(dateObject.toLocaleString('default', { month: 'short' }));
      const year = dateObject.getUTCFullYear();

      return `${month} ${day} ${year}`;
    } else {
      return "No date selected";
    }
  };

  handleDateChange = (key: keyof S) => (newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  handleCheckBoxClick = (id: number) => {
    const selectedIndex = this.state.selectedOrders.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selectedOrders, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selectedOrders.slice(1));
    } else if (selectedIndex === this.state.selectedOrders.length - 1) {
      newSelected = newSelected.concat(this.state.selectedOrders.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selectedOrders.slice(0, selectedIndex),
        this.state.selectedOrders.slice(selectedIndex + 1)
      );
    }

    this.setState({ selectedOrders: newSelected});
  };

  handleSortRequest = (property: keyof WorkOrder, order: string) => {
    const workOrdersCopy = [...this.state.workOrders];
    workOrdersCopy.sort((a, b) => {
      if (a[property] < b[property]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
    this.setState({ workOrders: workOrdersCopy});
  };

  isSelected = (id: number) => this.state.selectedOrders.indexOf(id) !== -1;

  convertNumberToWords = (n: number) => {
    if (n < 0)
      return false;
  
    // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
    const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    
    if (n === 0) return 'Zero';
    
    // Recursive function to translate the number into words
    function translate(n: number) {
        let word = "";
        if (n < 10) {
            word = single_digit[n] + ' ';
        } else if (n < 20) {
            word = double_digit[n - 10] + ' ';
        } else if (n < 100) {
            let rem = translate(n % 10);
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
        } else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
        }
        return word;
    }

    // Get the result by translating the given number
    let result = translate(n);
    return result.trim();
  }

  handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    } as unknown as Pick<S, keyof S>);
    if (name === "price") {
      this.validatePrice(value);
    } else if (name === "taxes") {
      this.validateTaxes(value);
    }
  };

  validatePrice = (price: string) => {
    const priceRegex = /^\d{1,10}(\.\d{0,2})?$/;
    if (!priceRegex.test(price)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          price: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          price: false,
        },
      }));
    }
  };

  validateTaxes = (tax: string) => {
    const taxRegex = /^\d{1,10}(\.\d{0,2})?$/;
    if (!taxRegex.test(tax)) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxes: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          taxes: false,
        },
      }));
    }
  }

  getHelperText = (field: string) => {
    const { errors } = this.state;

    if(field === "price" && errors.price) {
        return "Please enter a valid price.";
    }
    if(field === "taxes" && errors.taxes) {
      return "Please enter a valid tax cost.";
    }
    return null;
  };

  handleFilesChange = (name: string, files: FileWithPreview[]) => {
    this.setState({
      [name]: files,
    } as unknown as Pick<S, keyof S>,
    () => {
      this.validateFiles(name);
    });
  };

  handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      
      const newSearch = this.state.searchInput
      .split(",")
      .map((param) => param.trim())
      .filter((param) => param !== "");

      // Combine the new trades with the existing trades
      const combinedSearch = [...this.state.searchArray, ...newSearch];

      // Remove duplicate values
      const uniqueSearch = combinedSearch.filter(
        (item, index) => combinedSearch.indexOf(item) === index
      );

      this.setState({ searchArray: uniqueSearch, searchInput: "" });
    }
  };

  handleClearAll = () => {
    this.setState({ searchArray: [], searchInput: "" });
  }

  handleDelete = (trade: string) => {
    const updatedTrade = this.state.searchArray.filter(
      (item) => item !== trade
    );
    this.setState({ searchArray: updatedTrade });
  };

  validateField = (field: string) => {
    if (field === "receipts") {
      return this.state.errors.receipts;
    } else {
      return false;
    }
  };

  validateFiles = (name: string) => {
    if(name === "receipts") {
      if (this.state.receipts.length === 0) {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            receipts: true,
          },
        }));
      } else {
        this.setState((prevState) => ({
          errors: {
            ...prevState.errors,
            receipts: false,
          },
        }));
      }
    }
  };
  
  handleRowClick = (index: number) => {
    this.setState((prevState) => ({
      openDetailRow: prevState.openDetailRow === index ? null : index
    }));
  };

  handleMenuIconClick = (eventIcon: React.MouseEvent<HTMLElement>, rowId: number) => {
    console.log(eventIcon);
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: eventIcon.currentTarget
      },
    });
  };

  handleMenuClose = (rowId: number) => {
    this.setState({
      anchorNewElement: {
        ...this.state.anchorNewElement,
        [rowId]: null
      },
    });
  };

  handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  handleSendInvoiceClick = (orderIdArray: number[]) => {
    if(orderIdArray.length) {
      const currentWorkOrder = this.state.workOrders.find(order => order.id === orderIdArray[0]);
      this.setState({ sendInvoiceModal: true, modalWorkOrderArray: orderIdArray, currentWorkOrderIndex: 0, currentModalWorkOrder: currentWorkOrder });
    }
  };

  handleScheduleDateClick = (orderIdArray: number[]) => {
    if(orderIdArray.length) {
      const currentWorkOrder = this.state.workOrders.find(order => order.id === orderIdArray[0]);
      this.setState({ scheduleDateModal: true, modalWorkOrderArray: orderIdArray, currentWorkOrderIndex: 0, currentModalWorkOrder: currentWorkOrder });
    }
  };

  handleModalClose = (type: string) => {
    if(type === 'sendInvoice') {
      this.setState({ sendInvoiceModal: false });
      if (this.state.currentWorkOrderIndex < this.state.modalWorkOrderArray.length - 1) {
        const currentWorkOrder = this.state.workOrders.find(order => order.id === this.state.modalWorkOrderArray[this.state.currentWorkOrderIndex+1]);
        this.setState((prevState) => ({
          currentWorkOrderIndex: prevState.currentWorkOrderIndex + 1,
          currentModalWorkOrder: currentWorkOrder
        }), ()=>{
          this.setState({ sendInvoiceModal: true })
        })
      }
    }
    else {
      this.setState({ scheduleDateModal: false });
      if (this.state.currentWorkOrderIndex < this.state.modalWorkOrderArray.length - 1) {
        const currentWorkOrder = this.state.workOrders.find(order => order.id === this.state.modalWorkOrderArray[this.state.currentWorkOrderIndex+1]);
        this.setState((prevState) => ({
          currentWorkOrderIndex: prevState.currentWorkOrderIndex + 1,
          currentModalWorkOrder: currentWorkOrder
        }), ()=>{
          this.setState({ scheduleDateModal: true })
        })
      }
    }
  };

  validateForm = () => {
    this.validateFiles("receipts");
    if (
      this.state.errors.receipts ||
      this.state.errors.price ||
      this.state.errors.taxes
    ) {
      return false;
    }
    return true
  }

  // Customizable Area End
}
