import React from "react";

// Customizable Area Start
import {
  Grid,
  Container,
  RadioGroup,
  FormControlLabel,
  Button,
  Box,
  Checkbox,
  Paper,
  Typography,
} from "@mui/material";
import {
  createTheme,
} from "@mui/material/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import { activeProfile, keasyIcon, profileMain, projects, activeProjects, bottomImageIcon } from "./assets";
import { CustomRadioButton } from "../../../components/src/CustomRadioButton.web";
// Customizable Area End

import TaskController, { Props, configJSON } from "./TaskController";

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

export default class Task extends TaskController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Grid container direction="row" wrap="nowrap" 
            sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
        >
          <Grid item sx={webStyles.navBarItem}>
            <NavigationSidebar
                data-test-id={"vendorId"}
                activeItem={this.state.activeVendorItem}
                sidebarItems={[
                    { label: configJSON.vendorProfile, labelKey: configJSON.vendorLabelProfile, icon: profileMain, activeIcon: activeProfile, pathName: configJSON.vendorLabelProfile },
                    { label: configJSON.projects, labelKey: configJSON.vendorProjects, icon: projects, activeIcon: activeProjects, pathName: configJSON.vendorProjects }
                ]}
                onClickSidebar={this.handleVendoritemClick}
                keasylogo={keasyIcon}
                openDrawer={this.state.openVendorDrawer}
                onClickDrawer={this.handleVendorDrawer}
            />
          </Grid>
          <Grid item sx={webStyles.mainContent}>
          <Container maxWidth={false} sx={webStyles.mainContainer}
            >
                <Typography sx={webStyles.vendor_profile_header} paragraph>
                  Work Order Details
                </Typography>
                
                <Paper elevation={3} sx={webStyles.contentPaper}>
                  <Grid container mt={2} ml={5}>
                    <Grid item md={6} xs={12}>
                      <Grid container>
                        <Grid item xs={6} md={4} mb={5}>
                          <Typography sx={{ fontWeight: 700, marginRight: "15px" }}>ORDER ID</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography sx={{ fontWeight: 700, marginRight: "15px", wordBreak: 'break-word' }}>
                            #{this.orderId}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>Date opened</Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.date}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Requested by
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8} sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.requestedBy}
                          </Typography>
                          <Typography sx={webStyles.typeBadge}>
                            Tenant
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Category
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.category}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Work needed
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.workNeeded}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Assigned vendor
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.assignedVendor}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Description
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Price
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <Typography style={{ wordBreak: 'break-word' }}>
                            {this.state.workOrder.projectAmount}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={4} mb={2}>
                          <Typography sx={webStyles.regularText}>
                            Vendor update
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                          <RadioGroup
                            defaultValue={this.state.statusUpdate}
                            aria-labelledby="statusUpdate"
                            name="statusUpdate"
                            value={this.state.statusUpdate}
                            onChange={this.handleInputChange}
                          >
                            <FormControlLabel
                              value="WORK COMPLETED"
                              id="completed"
                              control={<CustomRadioButton />}
                              label="Mark project as completed"
                              sx={{ marginBottom: "16px" }}
                            />
                            <FormControlLabel
                              value="WORK IN PROGRESS"
                              id="inProgress"
                              control={<CustomRadioButton />}
                              label="Mark project as in progress"
                              sx={{ marginBottom: "16px" }}
                            />
                            <FormControlLabel
                              value="AWAITING PAYMENT"
                              id="awaitingPayment"
                              control={<CustomRadioButton />}
                              label="Mark project as awaiting payment"
                              sx={{ marginBottom: "16px" }}
                            />
                            <FormControlLabel
                              value="PAID"
                              id="paid"
                              control={<CustomRadioButton />}
                              label="Mark project as paid"
                              sx={{ marginBottom: "16px" }}
                            />
                          </RadioGroup>
                        </Grid>
                        <FormControlLabel
                          label="I agree with the disclaimer (insert disclaimer text here)"
                          control={<Checkbox
                                      checked={this.state.agreeCheckbox} 
                                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({agreeCheckbox: e.target.checked})
                                      }}
                                      sx={{
                                        '&.Mui-checked': {
                                          color: "#DAA520",
                                        },
                                      }}
                                  />}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              marginTop: '5px'
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box display={"flex"} justifyContent={"end"} sx={{ padding: "25px" }}>
                    <Button
                      size="medium"
                      id="sendButton"
                      type="submit"
                      sx={webStyles.form_button}
                      onClick={this.onStatusUpdateSubmit}
                    >
                      Update status
                    </Button>
                  </Box>
                </Paper>
                <img
                  src={bottomImageIcon}
                  alt="Description"
                  style={{
                      position: "absolute",
                      bottom: "-15px",
                      right: "0"
                  }}
                />
            </Container>
          </Grid>
        </Grid>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    }
  },
  vendor_profile_header: {
    fontFamily: "Outfit, sans-serif",
    fontSize: "30px",
    fontWeight: 600,
    lineHeight: "40px",
    letterSpacing: "-0.005em",
  },
  contentPaper: {
    border: "2px solid #FFF1CC",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "unset",
  },
  mainContent: {
    flexGrow: 1,
    "@media (min-width: 992px)": {
        borderLeft: "1px solid rgb(208,203,199)",
    },
    marginLeft: {md: "120px", sm: "0px", xs: "0px"}
  },
  mainContainer: {
    position: "relative",
    paddingBottom: "100px",
    paddingLeft: "36px !important",
    paddingRight: "36px !important"
  },
  regularText: {
    fontWeight: 400,
    color: "#BAB1AB",
    marginRight: "15px",
  },
  form_button: {
    backgroundColor: "#FFC123",
    fontFamily: "Outfit, sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    textTransform: "capitalize",
    color: "#000000",
    height: "44px",
    width: "184px",
    "&:hover": {
      backgroundColor: "#FFC123",
    },
    "&.Mui-disabled": {
      backgroundColor: "#FFF1CC",
    },
  },
  typeBadge: {
    position: "relative",
    display: "flex",
    fontSize: "0.9rem",
    wordBreak: 'break-word',
    marginLeft: "16px",
    backgroundColor: "#FFF1CC",
    flexBasis: "25%",
    height: "75%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px",
    top: "-5px",
  }
};
// Customizable Area End
