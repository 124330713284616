import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config.js");
// Customizable Area Start
const navigation = require("react-navigation");
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";

export interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  body?: object;
  type?: string;
}

export interface OpenSingleWorkOrder {
  data: {
    id: string;
    type: string;
    attributes: {
      id: number;
      unit: {
        id: number;
        name: string;
        status: string;
        property_id: number;
      },
      description: string;
      status: string;
      work_needed: string;
      cost: boolean;
      scheduled_date: string;
      sub_category: {
        sub_category: string;
        sub_category_id: number;
        category_id: number;
      },
      assigned_by: string;
      property: {
        property_name: string;
        code: string;
        address: string;
        floor: string;
        entrance: string;
      },
      vendor_account: {
        phone_number: string;
        id: number;
        name: string;
        email_address: string;
      },
      documents: Array<Documents>;
      work_order_audits: Array<AuditLogs>;
    }
  }
}

export interface AuditLogs {
  description: string;
  date: string;
  updated_by: string;
  cost: boolean;
  status: string;
}

export interface Documents   {
  id: string;
  file_name: string;
  file_url: string;
  file_size: string;
}

export interface CategoryDetails {
  data: Array<CategoryUpdateDetails>;
}

export interface CategoryUpdateDetails  {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    sub_categories: Array<SubCategory>;
  }
}

export interface SubCategory {
  id: number;
  name: string;
}

export interface SortDirection {
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
}

export interface FileWithPreview extends File {
  preview: string;
}
// Customizable Area End

export interface Props {
  navigation: typeof navigation;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeUpdateOrder: string;
  openShowOrder: boolean;
  orderId: number;
  openWorksOrder: OpenSingleWorkOrder;
  openError: string;
  sortState: SortDirection;
  catagoryId: string;
  updateWorkNeed: string;
  updateDescription: string;
  selectedUpdatedApprove: string | null;
  selectedUpdateValue: string;
  anchorElement: HTMLElement | null;
  openUpdateApprove: boolean;
  approveUpdateDetails: string[];
  catagoryDetails: Array<CategoryUpdateDetails>;
  fileDocuments: FileWithPreview[];
  fileNames: string;
  setPayBill: string;
  openFindKey: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class UpdateWorkOrderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOpenShowWorkApiCallId: string = "";
  getCategoryWorkApiCallId: string = "";
  putWorkOrderApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      activeUpdateOrder: configJSON.worksOrderlabel,
      openShowOrder: false,
      orderId: 0,
      openWorksOrder: {
        data: {
          id: "",
          type: "",
          attributes: {
            id: 0,
            unit: {
              id: 0,
              name: "",
              status: "",
              property_id: 0
            },
            work_needed: "",
            cost: false,
            description: "",
            status: "",
            scheduled_date: "",
            sub_category: {
              sub_category: "",
              sub_category_id: 0,
              category_id: 0
            },
            assigned_by: "",
            property: {
              property_name: "",
              address: "",
              floor: "",
              entrance: "",
              code: ""
            },
            vendor_account: {
              id: 0,
              name: "",
              phone_number: "",
              email_address: ""
            },
            documents: [],
            work_order_audits: []
          }
        }
      },
      openError: "",
      sortState: {
        sortColumn: '',
        sortDirection: 'asc',
      },
      catagoryId: "",
      updateWorkNeed: "",
      updateDescription: "",
      selectedUpdatedApprove: "",
      selectedUpdateValue: "",
      anchorElement: null,
      openUpdateApprove: false,
      approveUpdateDetails: [configJSON.assignVendor,configJSON.completedWork, configJSON.declinedWork],
      catagoryDetails: [],
      fileDocuments: [],
      fileNames: "",
      setPayBill: "",
      openFindKey: false
      // Customizable Area End
    };
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getOpenShowSingleDetails();
    this.getCategoryDetails();
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiReqsCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (!responseJson.errors && responseJson) {
        this.apiSuccessCallBacks(apiReqsCallId, responseJson);
      }
    }
  };

  workOpenShowApiCall = async (data: APIPayloadType) => {
    let { contentType, body, method, endPoint,type } = data;
    const tokens = await storage.get("authToken");
    const header = {
      "Content-Type": contentType,
      token: tokens
    };

    let requestNewMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
    requestNewMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      type == "formData" ? body:JSON.stringify(body)
    );

    runEngine.sendMessage(requestNewMessage.id, requestNewMessage);
    return requestNewMessage.messageId;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJson: OpenSingleWorkOrder & CategoryDetails) => {
    if (apiRequestCallID === this.getOpenShowWorkApiCallId) {
      this.setState({
        openWorksOrder: responseJson
      });
    }

    if (apiRequestCallID === this.getCategoryWorkApiCallId) {
      this.setState({
        catagoryDetails: responseJson.data
      });
    }

    if (apiRequestCallID === this.putWorkOrderApiCallId) {
      this.setState({
        openWorksOrder: responseJson,
        activeUpdateOrder: configJSON.worksOrderlabel
      },()=>{
        this.handleWorkOrderMenu();
      });
    }

  };


  getOpenShowSingleDetails = async () => {
    const workOrderId = await storage.get("openWorkId");
    this.getOpenShowWorkApiCallId = await this.workOpenShowApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: `${configJSON.getOpenWorkApiEndPoint}${workOrderId}`
    });
  };

  getCategoryDetails = async () => {
    this.getCategoryWorkApiCallId = await this.workOpenShowApiCall({
      contentType: configJSON.appointmentApiContentType,
      method: configJSON.getAppointmentListAPiMethod,
      endPoint: configJSON.getCatagoryAcceptanceApiEndPoint
    });
  };

  handleSideBarNav = (navKey: string) => {
    this.setState({ activeUpdateOrder: navKey }, () => {
      this.handleWorkOrderMenu();
    });
  };

  handleOpenDrawer = () => {
    this.setState({
      openShowOrder: !this.state.openShowOrder
    });
  };

  handleWorkOrderMenu = () => {
    const { activeUpdateOrder } = this.state;
    const toMsgShowWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgShowWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeUpdateOrder
    );
    toMsgShowWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgShowWork);
  };

  handleShowScrollContainer = (audits: AuditLogs[]) => {
    return audits.length > 4 ? "scrollable" : "";
  };

  handleUpdateSort = (property: string, direction: 'asc' | 'desc') => {
    this.setState((prevState) => ({
      sortState: {
        ...prevState.sortState,
        sortColumn: property,
        sortDirection: direction,
      },
    }));
  };

  sortShowAuditData = (newData: AuditLogs[]) => {
    const { sortColumn, sortDirection } = this.state.sortState;
    return [...newData].sort((sortingA: AuditLogs, sortingB: AuditLogs) => {
      const aValue = sortingA[sortColumn as keyof AuditLogs];
      const bValue = sortingB[sortColumn as keyof AuditLogs];
      if (bValue === null || bValue === undefined) return -1;
      if (typeof aValue === "string" && typeof bValue === "string") {
        return aValue.localeCompare(bValue) * (sortDirection === "asc" ? 1 : -1);
      } else if (typeof aValue === "number" && typeof bValue === "number") {
        return (aValue - bValue) * (sortDirection === "asc" ? 1 : -1);
      } else {
        return 0;
      }
    });
  };

  handleAuditLog = (rowOrders: AuditLogs[]) => {
    return rowOrders.length > 0 ? "" : "isUnitsDisabled";
  };

  handleEmptyShowAudit = (rowOrders: AuditLogs[]) => {
    return rowOrders.length > 0 ? "isUnitsDisabled" : "";
  };

  priorityUpdateStyleHandler = (newStatus: string) => {
    let styleObjPriority = {
      awaiting: {
        color: "#D97706",
        background: "#FEF3C7",
        borderColor: "#FEF3C7"
      },
      vendor: {
        background: "#D1FAE5",
        color: "#059669",
        borderColor: "#D1FAE5"
      },
      declined: {
        background: "#A3978F",
        borderColor: "#A3978F",
        color: "#413f3e"
      },
      pay_bill: {
        color: "#DC2626",
        background: "#FEE2E2",
        borderColor: "#FEE2E2"
      },
      completed: {
        color: "#00008B",
        background: "#ADD8E6",
        borderColor: "#ADD8E6"
      }
    }
    switch (newStatus) {
      case "COMPLETED":
        return styleObjPriority.completed
      case "WAITING LANDLORD APPROVAL":
        return styleObjPriority.awaiting
      case "DECLINED":
        return styleObjPriority.declined
      case "PAY BILL":
        return styleObjPriority.pay_bill
      case "ASSIGNED TO VENDOR":
        return styleObjPriority.vendor
      default:
        return styleObjPriority.awaiting
    }
  };

  handleShowPreview = (documents: Documents) => {
    window.open(documents.file_url, "_blank");
  };

  setUpdateCatagory = (catId: string) => {
    this.setState({
      catagoryId: catId
    });
  };

  setUpdateWorkNeed = (text: string) => {
    this.setState(prevState => ({
      openWorksOrder: {
        ...prevState.openWorksOrder,
        data: {
          ...prevState.openWorksOrder.data,
          attributes: {
            ...prevState.openWorksOrder.data.attributes,
            work_needed: text
          }
        }
      }
    }));
  };

  setUpdateDescription = (value: string) => {
    this.setState(prevState => ({
      openWorksOrder: {
        ...prevState.openWorksOrder,
        data: {
          ...prevState.openWorksOrder.data,
          attributes: {
            ...prevState.openWorksOrder.data.attributes,
            description: value
          }
        }
      }
    }));
  };

  handleUpdateApproveClick = (appEvent: React.MouseEvent<HTMLElement>) => {
    this.setState({
      openUpdateApprove: true,
      anchorElement: appEvent.currentTarget
    });
  };

  handleUpdateApproveMenu = () => {
    this.setState({
      openUpdateApprove: false
    });
  };

  getUpdatedStatus = (status: string) => {
    switch (status) {
      case "Complete work order":
      case "COMPLETED":
        return "completed";
      case "Transfer to other vendor":
      case "ASSIGNED TO VENDOR":
        return "assigned_to_vendor";
      case "Decline work order":
      case "DECLINED":
        return "declined";
      case "PAY BILL":
        return "pay_bill";
      default:
        return "";
    }
  };

  handleMenuClick = (selectedValue: string) => {
    this.setState({
      selectedUpdateValue: selectedValue,
      openUpdateApprove: false
    });
  };


  handleFileDocChange = (names: string, files: FileWithPreview[]) => {
    this.setState({
      fileDocuments: files,
      fileNames: names
    });
  };

  updateWorkOrderDetails = async (formData: FormData) => {
    const worksId = await storage.get("openWorkId");
    this.putWorkOrderApiCallId = await this.workOpenShowApiCall({
      method: configJSON.putAppointmentListAPiMethod,
      endPoint: `${configJSON.putWorkOrderApiEndPoint}${worksId}`,
      body: formData,
      type: "formData"
    });
  };

  handleUpdateSubmit = async(eventUpdate: React.FormEvent<HTMLFormElement>) => {
    const documentFile= this.state.openWorksOrder.data.attributes.documents;
    const fileDoc = this.state.fileDocuments
    eventUpdate.preventDefault();
    const formdata = new FormData();
    formdata.append("landlord_work_order[sub_category_id]", this.state.catagoryId || this.state.openWorksOrder.data.attributes.sub_category.sub_category_id.toString());
    formdata.append("landlord_work_order[work_needed]", this.state.openWorksOrder.data.attributes.work_needed);
    formdata.append("landlord_work_order[vendor_account_id]", this.state.openWorksOrder.data.attributes.vendor_account.id.toString());
    for (const items of documentFile) {
        const response = await fetch(items.file_url);
        const blob = await response.blob();
        const file = new File([blob], items.file_name || "file", { type: blob.type });
        formdata.append("landlord_work_order[documents][]", file);
      }
    for (const item of fileDoc) {
      formdata.append("landlord_work_order[documents][]", item);
    }
    formdata.append("landlord_work_order[description]", this.state.openWorksOrder.data.attributes.description);
    formdata.append("landlord_work_order[status]", this.getUpdatedStatus(this.state.openWorksOrder.data.attributes.status) || this.getUpdatedStatus(this.state.selectedUpdateValue));
    this.updateWorkOrderDetails(formdata);
  };

  handlePayBillDetails = (payBill: string) => {
    this.setState({
      selectedUpdateValue: payBill
    });
  };

  handleModal = () => {
    this.setState({
      openFindKey: !this.state.openFindKey
    });
  };

  setUpdatedStatus = (status: string) => {
    switch (status) {
      case "Complete work order":
        return "COMPLETED";
      case "Transfer to other vendor":
        return "ASSIGNED TO VENDOR";
      case "Decline work order":
        return "DECLINED";
      case "pay_bill":
        return "PAY BILL";
      default:
        return "WAITING LANDLORD APPROVAL";
    }
  };

  handleFileRemove = (file: Documents) => {
    this.setState(prevState => ({
      openWorksOrder: {
        ...prevState.openWorksOrder,
        data: {
          ...prevState.openWorksOrder.data,
          attributes: {
            ...prevState.openWorksOrder.data.attributes,
            documents: prevState.openWorksOrder.data.attributes.documents.filter(
              (files) => files.id !== file.id
            ),
          },
        },
      },
    }));

    if (file.file_url.startsWith('blob:')) {
      URL.revokeObjectURL(file.file_url);
    }
  };

  capitalizeLetter = (capsValue: string) => {
    return capsValue.charAt(0).toUpperCase() + capsValue.slice(1);
  };

  handleBack = () => {
    window.history.back();
  };
  // Customizable Area End
}
