import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { FormikTouched, FormikErrors } from "formik";
import React from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Moment } from "moment/moment";
import { getStorageData } from "../../../framework/src/Utilities"
interface PropertyAttributes {
  units: any[]; 
}

interface Property {
  id: number | string;
  attributes: PropertyAttributes;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  available_date: Moment;
  id: string | number;
  token: string;
  activeOrderItem: string;
  property: any;
  unit: any;
  catagory: any;
  status: any |null;
  assignTo: any | null;
  startDate: Date | null;
  dueDate: Date | null;
  dueHour: any;
  dueDateFrequency: string,
  duration: string,
  description: string,
  vendor_account_id: number | null,
  allProperties: any,
  allTanent: any,
  allCatagory: any,
  allStatus: any,
  currentVendorIndex: number;
  units: any,
  activeMainItem:string,
  openLandlordDrawer: boolean,
  formData: any,
  isReminder: boolean,
  isEditReminder: boolean,
  // Customizable Area End
}

// Customizable Area Start
export interface Dropdown {
  value: string;
  label: string;
}
// Customizable Area End

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  propertyId: string | null;
  unitId: string | null;
  reminderId: string | null;
  addAppointmentApiCallId?: string;
  getPropertiesCallId?: string;
  getCatagoryCallId?: string;
  getTanentCallId?: string;
  getFormDataCallId?: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      this.propertyId = "",
      this.unitId = "",
      this.reminderId = "",
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      id: 0,
      available_date: moment(new Date()),
      token: "",
      activeOrderItem: configJSON.worksOrderlabel,
      property: null,
      unit: null,
      catagory: null,
      status: null,
      assignTo: null,
      startDate: null,
      dueDate: null,
      dueHour: null,
      dueDateFrequency: "",
      duration: "",
      description: "",
      vendor_account_id: null,
      allProperties: [],
      allTanent: [],
      allCatagory: [],
      allStatus: [],
      currentVendorIndex: 0,
      units: [],
      activeMainItem: "AutomatedPrioritization",
      openLandlordDrawer:false,
      formData: {},
      isReminder: false,
      isEditReminder: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    this.propertyId = this.props.navigation.getParam("propertyId", null);
    this.unitId = this.props.navigation.getParam("unitId", null);
    this.reminderId = this.props.navigation.getParam("reminderId",null);
    const path = window.location.pathname;
    const containsNewReminder = path.includes('Open');
    const response = await getStorageData("authToken")
    if (response) {
      this.setState({ token: response }, () => {
      })
    }
    this.getCatagory();
    this.getTanent();
    this.setState({
      isReminder: path.includes('Open'),
      isEditReminder: path.includes('EditReminders'),
    });
    if(this.reminderId) {
      this.getFormData();
    }
    if(this.propertyId && this.unitId) {
      this.getProperties(this.propertyId, this.unitId);
      this.setState({
        isReminder: path.includes('Open'),
        property: Number(this.propertyId),
        unit: Number(this.unitId),
      });
    } else {
      this.getProperties("","");
    }
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    if (this.handlePropertiesResponse(message)) {
      return;
    }

    // Handle category response
    if (this.handleCategoryResponse(message)) {
      return;
    }

    if(this.handleTanentResponse(message)) {
      return;
    }
    if(this.handleFormDataResponse(message)){
      return;
    }

    this.handleAddAppointmentResponse(message);

    // Customizable Area End
  }

  // Customizable Area Start
  handleMainItemClick = (labelKey: string) => {
    this.setState({ activeMainItem: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainItem } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainItem
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleLandlordDrawer = () => {
    this.setState({
      openLandlordDrawer: !this.state.openLandlordDrawer,
    });
  };

  private handlePropertiesResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getPropertiesCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllProperty(responseJson.data);
      }
      return true;
    }
    return false;
  }

  private handleCategoryResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatagoryCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllCatagory(responseJson.data[1].attributes.sub_categories[3]);
        this.setAllStatus(responseJson.data[1].attributes.sub_categories[2].sub_category_menus)
      }
      return true;
    }
    return false;
  }
  
  private handleFormDataResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFormDataCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setFormData(responseJson.data.attributes);
      }
      return true;
    }
    return false;
  }

  private handleTanentResponse(message: Message): boolean {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getTanentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setAllTanents(responseJson.data);
      }
      return true;
    }
    return false;
  }

  private handleAddAppointmentResponse(message: Message): void {
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.addAppointmentApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.navigateToAppointments();
      }
    }
  }

  getProperties = async (propertyId: any, unitId: any) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPropertiesCallId = requestMessage.messageId;
      if(propertyId === ""){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getPropertiesAcceptanceApiEndPoint
        );
      } else {
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.getPropertiesAcceptanceApiEndPoint}?property_unit_filter=${propertyId}:${unitId}`
        );
      }
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCatagory = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatagoryCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCatagoryAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getFormData = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFormDataCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.appointmentAPiEndPoint}/${this.reminderId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getTanent = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTanentCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getTanentAcceptanceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAppointmentListAPiMethod
    );
   
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setProperty = (id: any) => {
    this.setState(prevState => ({
      property: id,
      units: prevState.allProperties.find((property: any) => Number(property?.id) === id)?.attributes.units || []
    }));
  };

  setUnit = (text: number) => {
    this.setState({
      unit: text,
    });
  };

  setCatagory = (id: number) => {
    this.setState({
      catagory: id,
    });
  };

  setStatus = (name: any) => {
    this.setState({
      status: name,
    });
  };

  setassignTo = (name: any) => {
    this.setState({
      assignTo: name,
    });
  };

  setDueFrequency = (frequency: string) => {
    this.setState({
      dueDateFrequency: frequency,
    });
  };

  setDuration = (duration: string) => {
    this.setState({
      duration: duration,
    });
  };

  handleDateChange = (key: keyof S, newValue: Date | null) => {
    this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
  };

  setDescription = (value: string) => {
    this.setState({
      description: value,
    });
  };

  setVendorId = (id: number) => {
    this.setState({
      vendor_account_id: id,
    });
  }

  setAllProperty = (property: Property[]) => {
    this.setState({
      allProperties: property,
    });
    this.setState({
      units: property.length > 0 ? property[0]?.attributes?.units : []
    });
  };

  setAllCatagory = (catagory: []) => {
    this.setState({
      allCatagory: catagory,
    });
  };

  setAllTanents = (tanent: []) => {
    this.setState({
      allTanent: tanent,
    });
  };
  setFormData = (formData: any) => {
    this.setState({
      formData: formData,
    });
    
    this.setState({
      property: formData.property.id,
      unit: formData.unit.id,
      catagory:formData.sub_category.id,
      status: formData.status,
      dueDate: new Date(formData.due_date),
      startDate: new Date(formData.start_date),
      dueHour: new Date(`${formData.start_date}T${formData.start_time}:00`),
      duration: formData.duration,
      description: formData.comments,
      dueDateFrequency: formData.frequency,
      assignTo: formData.assigned_to.id,
    })
  };

  setAllStatus = (status: []) => {
    this.setState({
      allStatus: status,
    });
  };

  addAppointment() {

    const header = {
      "Content-Type": 'application/json',
      token: this.state.token,
    };

    const dueDate = this.state.dueDate ? moment(this.state.dueDate).format("DD-MM-YYYY") : null;
    const dueHour = this.state.dueHour ? moment(this.state.dueHour).format("HH:mm") : null;
    const startDate = this.state.startDate ? moment(this.state.startDate).format("DD-MM-YYYY") : null;
    const body = {
      reminder: {
      property_id: this.state.property,
      unit_id: this.state.unit,
      sub_category_id: this.state.catagory,
      status: this.state.status,
      due_date: dueDate,
      start_date: startDate,
      start_time: dueHour,
      duration: this.state.duration,
      comments: this.state.description,
      frequency: this.state.dueDateFrequency,
      assigned_to: this.state.assignTo,
      }
    };

    const requestMethod = this.state.isEditReminder 
    ? configJSON.putReminderMethod  
    : configJSON.addAppointmentAPiMethod; 

    const endpoint = this.state.isEditReminder ? `${configJSON.appointmentAPiEndPoint}/${this.reminderId}` : configJSON.appointmentAPiEndPoint
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addAppointmentApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      requestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToAppointments = () => {
    this.props.navigation.goBack();
  };
  navigateToWorkOrders = () => {
    const navigationMessage = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "AddAppointment"
    );
    navigationMessage.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
  };

  update(value: Partial<{ [K in keyof S]: S[K] }>) {
    this.setState((state) => ({ ...state, ...value }));
  }

  toMomentDate(value: string | Date | null, format?: string) {
    return moment(value, format);
  }

  toDateFromMoment(value: Moment) {
    return value.toDate();
  }


  // Customizable Area End
}
