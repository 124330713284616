import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  // Customizable Area Start
  Grid,
  Card,
  CardContent,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  PaginationItem,
  styled,
  InputBase,
  TextField,
  ClickAwayListener,
  // Customizable Area End
  IconButton,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import "@mui/styles";
import NavigationSidebar from "../../../components/src/NavigationSidebar.web";
import PopupMessage from "../../../components/src/PopupMessage.web";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { 
  ArrowDropDown,
  ArrowDropUp,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { 
  activeMainDashboard, 
  activeMainReminder, 
  activePropertyMain, 
  activeMainFinancial, 
  keyMainChain, 
  activeKeyMain, 
  keasyIcon, 
  dashboardLandLogo, 
  financialMain, 
  propertyMain, 
  remindersMain, 
  bottomImageIcon,
} from "./assets";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import  CustomNavbar from "../../../components/src/CustomNavbar.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import BaselineReportingController, {
  Props,
  configJSON,
} from "./BaselineReportingController";

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class BaselineReporting extends BaselineReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  PaymentCard = (title: string, amount: string, date: string) => {
    return (
        <Card sx={{ display: 'flex', alignItems: 'center', boxShadow: 3, borderRadius: 2, paddingRight: "50px", position: 'relative', marginLeft: {xs: "0", sm: "0", md: "36px"}, marginTop: {xs: "16px", sm: "16px", md: "0"} }}>
        {/* Yellow Side Bar */}
        <Box sx={{ width: '6px', height: '100%', backgroundColor: '#FFC107', position: 'absolute', left: 0, borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px' }} />
    
        {/* Content */}
        <CardContent sx={{ paddingLeft: '20px', paddingTop: '8px', paddingBottom: "8px !important" }}>
            <Typography sx={webStyles.regularTextValue}>
                {title}
            </Typography>
            <Typography variant="h4" sx={{ fontWeight: 600 }}>
                ${amount}
            </Typography>
            <Typography sx={{ ...webStyles.regularTextValue, color: date === 'Up to date' ? '#059669' : '#000000', minHeight: "24px" }}>
                {date}
            </Typography>
        </CardContent>
        </Card>
    );
  };

  renderSortableTableCell = (property: string, label: string) => {
    return (
        <CustomTableCell key={property}>
        <Box display="flex" alignItems="center" justifyContent="center">
            {label}
            <Box display="flex" flexDirection="column" marginLeft={"10px"}>
            <CustomIconButtonUp
                id={`ascendingIcon-${property}`}
                onClick={() => this.handleSortRequest(property, "asc")}
            >
                <ArrowDropUp />
            </CustomIconButtonUp>
            <CustomIconButtonDown
                id={`descendingIcon-${property}`}
                onClick={() => this.handleSortRequest(property, "desc")}
            >
                <ArrowDropDown />
            </CustomIconButtonDown>
            </Box>
        </Box>
        </CustomTableCell>
    );
  };

  renderStatusChip = (status: string) => {
    if (status.toUpperCase() === "CANCELED") {
        return (
        <Chip
            label="CANCELED"
            sx={{
            backgroundColor: "#FEE2E2",
            color: "#DC2626",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
        );
    } else if (status.toUpperCase() === "COMPLETED") {
        return (
        <Chip
            label="COMPLETED"
            sx={{
            backgroundColor: "#D1FAE5",
            color: "#059669",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
        );
    } else if (status.toUpperCase() === "PENDING") {
      return (
        <Chip
            label="PENDING"
            sx={{
            backgroundColor: "#FEF3C7",
            color: "#D97706",
            fontFamily: 'Outfit',
            fontWeight: "700",
            fontSize: '12px',
            borderRadius: "20px",
            }}
        />
      );
    }
  };

  screenProps = {
    navigation: this.props.navigation,
    id: "BaselineReporting",
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container direction="row" wrap="nowrap" 
                sx={{ flexDirection: {xs: "column", sm: "column", md: "row"}}}
            >
                <PopupMessage
                    open={this.state.popUpOpen}
                    type={this.state.popUpType}
                    message={this.state.popUpMessage}
                    handleClose={this.handlePopupMessageClose}
              />
            <Grid item sx={webStyles.navBarItem}>
                <NavigationSidebar
                    data-test-id={"tenantId"}
                    activeItem={this.state.activeTenantItem}
                    sidebarItems={[
                        { label: configJSON.dashboardLandloard, labelKey: configJSON.tenantDashboard, icon: dashboardLandLogo, activeIcon: activeMainDashboard, pathName: configJSON.tenantDashboard },
                        { label: configJSON.serviceRequests, labelKey: configJSON.servicelabel, icon: remindersMain, activeIcon: activeMainReminder, pathName: configJSON.servicelabel },
                        { label: configJSON.lease, labelKey: configJSON.leaselabel, icon: propertyMain, activeIcon: activePropertyMain, pathName: configJSON.leaselabel },
                        { label: configJSON.paymentHistory, labelKey: configJSON.paymentHistorylabel, icon: financialMain, activeIcon: activeMainFinancial, pathName: configJSON.paymentHistorylabel },
                        { label: configJSON.chatLandloard, labelKey: configJSON.chatTenantabel, icon: keyMainChain, activeIcon: activeKeyMain, pathName: configJSON.chatTenantabel }
                    ]}
                    onClickSidebar={this.handleTenantitemClick}
                    keasylogo={keasyIcon}
                    openDrawer={this.state.openTenantDrawer}
                    onClickDrawer={this.handleTenantDrawer}
                />
            </Grid>
            <Grid item sx={webStyles.mainContent}>
                <Container
                maxWidth={false}
                sx={{ position: "relative", paddingBottom: "100px", paddingLeft: "40px !important", paddingRight: "40px !important" }}
                >
                  <CustomNavbar 
                    {...this.screenProps}
                    showChatButton={true}   
                    showPropertiesButton={false} 
                  />
                    <Typography sx={webStyles.dashboardHeader} paragraph>
                            Payment History
                        </Typography>

                        <Grid container sx={webStyles.mainContainer} spacing={2}>
                            <Grid item xs={12} sx={{padding: 0}}>

                              <ClickAwayListener onClickAway={() => this.setState({ startDateOpen: false,endDateOpen:false })}  mouseEvent="onMouseDown">
                                  <Box display="flex" flexWrap="wrap" alignItems="center" mb={2}>
                                      <Grid container spacing={2} sx={{ width: '100%', alignItems: 'center' }}>
                                        <Grid item xs={6} sm={6} md={3}>
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box >
                                              <DesktopDatePicker
                                                  data-test-id="desktopDatePicker"
                                                  value={this.state.startDate}
                                                  onChange={(date) => this.handleDateChange(date, 'startDate')}
                                                  onClose={() => this.setState({ startDateOpen: false })}
                                                  open={this.state.startDateOpen} 
                                                  renderInput={(params) =>
                                                    <TextField
                                                    data-test-id="startDateDatePicker"
                                                    {...params}
                                                    label="Start date"
                                                    onClick={()=>{this.handleOpen("startDate")}}
                                                    sx={{
                                                      '& .MuiSvgIcon-root': {
                                                        color: '#BAB1AB',
                                                      },
                                                      '& .MuiInputBase-root': {
                                                        paddingRight: '8px',
                                                      },
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        lineHeight: '19px', 
                                                        fontSize: '16px',
                                                        color: '#A3978F',
                                                        fontWeight: 400, 
                                                        fontFamily: 'Outfit',
                                                      },
                                                    }}
                                                    
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      style: {
                                                        fontSize: '16px',
                                                        padding: '0rem 1rem',  
                                                        fontWeight: 400, 
                                                        fontFamily: 'Outfit, sans-serif', 
                                                        borderRadius: '8px',
                                                        lineHeight: '18px', 
                                                        width: '100%', 
                                                      },
                                                    }}
                                                  />
                                                    }
                                                  label={"start date"}
                                                />
                                              </Box>
                                          </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={3}>
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Box >
                                              <DesktopDatePicker
                                                  data-test-id="endDesktopDatePicker"
                                                  value={this.state.endDate}
                                                  onChange={(date) => this.handleDateChange(date, 'endDate')}
                                                  onClose={() => this.setState({ endDateOpen: false })}
                                                  open={this.state.endDateOpen}
                                                  renderInput={(params) =>
                                                    <TextField
                                                    data-test-id="endDesktopDatePickerField"
                                                    {...params}
                                                    label="End date"
                                                    onClick={()=>{this.handleOpen("endDate")}}
                                                    InputProps={{
                                                      ...params.InputProps,
                                                      style: {
                                                        width: '100%', 
                                                        borderRadius: '8px',
                                                        padding: '0rem 1rem', 
                                                        fontFamily: 'Outfit, sans-serif', 
                                                        fontSize: '16px', 
                                                        fontWeight: 400, 
                                                        lineHeight: '18px', 
                                                      },
                                                    }}
                                                    InputLabelProps={{
                                                      style: {
                                                        fontFamily: 'Outfit', 
                                                        fontSize: '16px',
                                                        fontWeight: 400, 
                                                        lineHeight: '19px', 
                                                        color: '#A3978F',
                                                      },
                                                    }}
                                                    sx={{
                                                      '& .MuiInputBase-root': {
                                                        paddingRight: '8px',
                                                      },
                                                      '& .MuiSvgIcon-root': {
                                                        color: '#BAB1AB',
                                                      },
                                                    }}
                                                  />
                                                    }
                                                />
                                            </Box>
                                          </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={4} sm={4} md="auto">
                                          <Button
                                            data-test-id="apply-btn"
                                            sx={webStyles.applyBtn} 
                                            onClick={this.handleFilter}
                                          >
                                            Apply
                                        </Button>
                                        </Grid>
                                        <Grid item xs={8} sm={8} md="auto" sx={{ ml: { md: 'auto' } }}>
                                          <Button
                                            variant="contained"
                                            data-test-id="transaction-btn"
                                            startIcon={<AddCircleOutlineIcon />}
                                            sx={{
                                              width:"14rem",
                                              height:"2.2rem",
                                              backgroundColor: '#FFF1CC',
                                              color: '#000', 
                                              borderRadius: '8px', 
                                              fontSize: '16px', 
                                              fontFamily: "Outfit",
                                              fontWeight: 700, 
                                              padding: '10px 16px',
                                              textTransform: 'none', 
                                              boxShadow: 'none',
                                              '&:hover': {
                                                backgroundColor: '#FFF1CC', 
                                                boxShadow: 'none', 
                                              },
                                            }}
                                            onClick={this.handleNewTransaction}
                                          >
                                              Add new transaction
                                          </Button>
                                        </Grid>
                                      </Grid>
                                  </Box>
                                </ClickAwayListener>
                                <div style={webStyles.borderSection}>

                                    <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "48px", flexDirection: {xs: "column", sm: "column", md: "row"}}}>
                                        <Box sx={{ display: "flex", alignItems: "center", width: {xs: "100%", sm: "100%", md: "47%"} }}>
                                          <Box
                                            sx={webStyles.searchRow}
                                          >
                                            <SearchIcon sx={{ color: "#BAB1AB" }} />
                                            <InputBase
                                              name="searchInput"
                                              id="searchInput"
                                              data-test-id="search_block"
                                              placeholder="Search"
                                              inputProps={{ "aria-label": "search" }}
                                              size="small"
                                              sx={{
                                                width: "100%",
                                                marginLeft: "5px",
                                                marginTop: "3px",
                                              }}
                                              value={this.state.searchInput}
                                              onChange={this.handleInputChange}
                                              onKeyPress={this.handleKeyPress}
                                            />
                                          </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: {xs: "column", sm: "column", md: "row"}}}>
                                          <Box sx={{ marginTop: {xs: "12px", sm: "12px", md: "0"} }}>
                                              {this.PaymentCard("Last payment", this.state.paymentData.last_payment, this.state.paymentData.last_transaction_date ?? " ")}
                                          </Box>
                                          <Box sx={{ marginTop: {xs: "12px", sm: "12px", md: "0"} }}>
                                              {this.PaymentCard("In debt", this.state.paymentData.pending_amount, this.state.paymentData.pending_amount === "0.0" ? "Up to date" : " ")}
                                          </Box>
                                          <Box sx={{ marginLeft: {xs: "0", sm: "0", md: "16px"}, marginTop: {xs: "16px", sm: "26px", md: "0"} }}>
                                            <Typography sx={webStyles.subHeading} gutterBottom>
                                                Upcoming payment in
                                            </Typography>
                                            <Typography variant="h4" sx={{ fontWeight: 600, color: "#DAA520" }} gutterBottom>
                                                {this.state.paymentData.upcoming_payment_date}
                                            </Typography>
                                          </Box>
                                        </Box>
                                    </Box>
                                    <TableContainer component={Paper}>
                                        <Table>
                                        <TableHead>
                                            <TableRow>
                                            {this.renderSortableTableCell("id", "Reference")}
                                            {this.renderSortableTableCell("transaction_date", "Transaction date")}
                                            {this.renderSortableTableCell("property", "Property")}
                                            {this.renderSortableTableCell("description", "Description")}
                                            <CustomTableCell key="details" align="center">
                                                Details
                                            </CustomTableCell>
                                            {this.renderSortableTableCell("amount", "Deposit amount")}
                                            {this.renderSortableTableCell("transaction_status", "Transaction status")}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.paginatedRows.map((row, index) => {
                                            const labelId = `enhanced-table-checkbox-${row.id}`;

                                            return (
                                                <>
                                                <TableRow
                                                hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.id}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        id={labelId}
                                                        scope="row"
                                                        padding="none"
                                                    >
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">#{row.id}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">{row.transaction_date}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">{row.property}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px' }} align="center">{row.description}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography align="center">
                                                        {row.details}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography align="center" sx={{ fontFamily: 'Outfit', fontWeight: 400, fontSize: '12px', color: '#059669' }}>
                                                        ${row.amount}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell align="center" >
                                                        {this.renderStatusChip(row.transaction_status)}
                                                    </TableCell>
                                                </TableRow>
                                                </>
                                            );
                                            })}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                        <Box sx={webStyles.paginationContainer}>
                                        <Box></Box>
                                        <Pagination
                                            data-test-id="pagination"
                                            count={Math.ceil(this.state.paymentHistory.length / this.state.rowsPerPage)}
                                            page={this.state.currentPage}
                                            onChange={this.handleChangePage}
                                            renderItem={(item) => (
                                            <PaginationItem
                                                {...item}
                                                sx={{
                                                "&.Mui-selected": {
                                                    color: "#CC9200",
                                                    backgroundColor: "unset",
                                                },
                                                }}
                                            />
                                            )}
                                        />
                                        <Typography sx={{ marginRight: "10%" }}>
                                            {`${(this.state.currentPage - 1) * this.state.rowsPerPage + 1}-${Math.min(
                                              this.state.currentPage * this.state.rowsPerPage,
                                              this.state.paymentHistory.length
                                            )} of ${this.state.paymentHistory.length} results`}
                                        </Typography>
                                    </Box>

                                </div>
                            </Grid>
                        </Grid>
                        <img
                            src={bottomImageIcon}
                            alt="Description"
                            style={{
                                position: "absolute",
                                bottom: "-15px",
                                right: "0"
                            }}
                        />
                </Container>
                </Grid>
            </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  dashboardHeader: {
    fontSize: "24px",
    fontFamily: "Outfit",
    fontWeight: 700,
    color: "#000000",
    marginBottom: "0",
  },
  navBarItem: {
    position: "fixed",
    top: 0,
    left: 0,
    height: "100%",
    overflowY: "auto",
    zIndex: 1000,
    "@media(max-width: 992px)": {
      position: "unset",
      height: "auto",
      overflowY: "hidden",
      border: "none",
    },
    scrollbarWidth: "none", 
    msOverflowStyle: "none",
    '&::-webkit-scrollbar': {
      display: 'none',
    }
  },
  mainContainer: {
      paddingTop: "20px",
      paddingBottom: "20px",
      "@media (max-width: 600px)": {
        flexDirection: "column",
      },
  },
  mainContent: {
      marginLeft: 0,
      "@media (min-width: 992px)": {
          borderLeft: "1px solid rgb(208,203,199)",
          marginLeft: "125px",
          width: 'calc(100% - 125px)',
      }
    },
  regularTextValue: {
    fontSize: "14px",
    fontFamily: "Outfit",
    fontWeight: 400,
    wordBreak: 'break-word',
  },
  borderSection:{
      border: "2px solid #FFD466",
      borderRadius: "8px",
      borderBottomRightRadius: "30px",
      padding: "34px 50px",
      boxShadow: "unset",
  },
  carouselArrow: {
      color: 'black',
      backgroundColor: '#FFC123',
      cursor: 'pointer',
    },
  carouselRightArrow: {
      color: 'black',
      backgroundColor: '#FFC123',
      cursor: 'pointer',
      position: 'absolute',
      outline: 0,
      transition: 'all .5s',
      borderRadius: '35px',
      zIndex: 1000,
      border: 0,
      minWidth: '43px',
      minHeight: '43px',
      opacity: 1,
      right: 'calc(0% + 1px)',
  },
  modal_header: {
      display: "flex",
      justifyContent: "space-between",
      p: 2,
  },
  headerButton: {
      fontFamily: "Outfit, sans-serif",
      fontSize: "14px",
      fontWeight: 700,
      lineHeight: "22px",
      color: "#CC9200",
      textTransform: "initial",
      paddingTop: 0,
  },
  icon: {
      marginRight: "10px",
      color: "#CC9200",
  },
  subHeading: {
    fontSize: "16px",
    fontFamily: "Outfit",
    fontWeight: 700,
  },
  slider: {
    margin: '0 20px',
    overflow: 'hidden',
    padding: '2rem 0',
  },
  sliderImage: {
    width: '100%',
    borderRadius: '10px',
    height: '275px'
  },
  reactMultiCarouselList: {
    padding: '0rem 0 2rem 0',
  },
  customDotListStyle: {
    button: {
      border: 'none',
      background: 'rgb(255, 68, 68)',
    },
  },
  activeDotButton: {
    background: 'rgb(255, 68, 68)',
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
  },
  searchRow: {
    border: "1px solid #BAB1AB",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    padding: "5px",
    width: "60%",
    height: "20%",
  },
  applyBtn: {
    height: "3.7rem",
    fontFamily: "Outfit",
    fontSize: "16px",
    fontWeight: 700,
    color: "#000000",
    textAlign: "center",
    backgroundColor: "#FFC123",
    borderRadius: "8px",
    textTransform: "none",
    cursor: "pointer",
    boxShadow: "none",
    width: '5rem',
    "&:hover": {
      backgroundColor: "#FFC123", 
    },
  }
};

const CustomTableCell = styled(TableCell)({
  color: "#A3978F",
  textAlign: "center",
  fontFamily: 'Outfit',
  fontSize: "12px",
  fontWeight: 700,
  padding: "0px 10px 0px 10px !important",
  margin: "auto",
});
  
const CustomIconButtonUp = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px",
});

const CustomIconButtonDown = styled(IconButton)({
  cursor: "pointer",
  height: "12px",
  width: "12px"
});
// Customizable Area End
