import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { AlertColor } from "@mui/lab";
import moment from "moment";

export interface FileWithPreview extends File {
  preview: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  activeMainNewrequest: string,
  workNeeded:string,
  openTenentDrawers: boolean,
  valueNew: string,
  errorNewRequest: string,
  dateOfBirthDateNewrequest: Date | null;
  dueHourNew:string,
  availabilityState: string,
  permissionToEnter: boolean,
  cost: string,
  description:string,
  submiState:string,
  dueDate: Date | null,
  dueHour: Date | null,
  dueDateFrequency: string,
  remindMeOnDate: Date | null;
  remindMeOnHour: Date | null;
  messageOpen: boolean;
  messageType: AlertColor;
  message: string;
  allCatagory: any;
  catagory:number;
  remindMeFrequency:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AutomatedworkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatagoryCallId: string = "";
  addServiceApiCallId:string="";
  async componentDidMount() { 
    this.getCatagory();
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      activeMainNewrequest: "landlordproperties",
      openTenentDrawers: false,
      valueNew: '',
      cost: "",
      dateOfBirthDateNewrequest: null,
      errorNewRequest: '',
      dueHourNew: "",
      availabilityState: "",
      submiState:"",
      permissionToEnter: false,
      description:"",
      dueDate: null,
      dueHour: null,
      dueDateFrequency: "",
      remindMeOnDate: null,
      remindMeOnHour: null,
      workNeeded:"",
      messageOpen: false,
      messageType: "success",
      message: "",
      allCatagory: [],
      catagory:0,
      remindMeFrequency:"",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (apiRequestCallId === this.getCatagoryCallId) {      
      if (!responseJson.errors && responseJson) {
        this.setState({
          allCatagory:responseJson.data
         });
      } else if (responseJson && responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      }
      };
    if (apiRequestCallId === this.addServiceApiCallId) {  
  
      if (responseJson && !responseJson.errors) {
        this.setState({
          messageOpen: true,
          messageType: "success",
          message: "Service request added successfully"
        });
      } else if (responseJson && responseJson.errors) {    
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: responseJson.errors
        });
      } else if (responseJson == null || responseJson === undefined) {
        this.setState({
          messageOpen: true,
          messageType: "error",
          message: "No response from server"
        });
      }
      };
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  
  handleMainItemClicknewrequest = (labelKey: string) => {
    this.setState({ activeMainNewrequest: labelKey }, () => {
      this.handleWorkOrderMenu();
    });
  };
  handleWorkOrderMenu = () => {
    const { activeMainNewrequest } = this.state;
    const toMsgWork = new Message(getName(MessageEnum.NavigationMessage));
    toMsgWork.addData(
      getName(MessageEnum.NavigationTargetMessage),
      activeMainNewrequest
    );
    toMsgWork.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(toMsgWork);
  };
  handleTenentrequestDrawers = () => {
    this.setState({
      openTenentDrawers: !this.state.openTenentDrawers
    });
  };

  handleDateChange = (key: keyof S, newValue: Date | null) => {
  this.setState({ [key]: newValue } as unknown as Pick<S, keyof S>);
};

setFieldValue = (field: keyof S, value: string) => {
  this.setState({ [field]: value } as unknown as S);
};

setAvailabilityState = (value: string) => {
  this.setState({
    availabilityState: value,
  });
};

setDescription = (value: string) => {
  this.setState({
    description: value,
  });
};
setWorkNeeded = (text: string) => {
  this.setState({
    workNeeded: text,
  });
};

getCatagory = async () => {
  const token = await getStorageData("authToken")

    const header = {
      token: token
    };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.getCatagoryCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.getCatagorApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethodType
  );
 
  runEngine.sendMessage(requestMessage.id, requestMessage);
};

addAppointment= async(value:any)=> {
  const token = await getStorageData("authToken")
  const header = {
    token: token,
    "Content-Type": "application/json",
  };
  const dueDate = this.state.dueDate ? moment(this.state.dueDate).format("DD-MM-YYYY") : null;
  const dueHour = this.state.dueHour ? moment(this.state.dueHour).format("HH:mm") : null;
  const remindMeOnDate = this.state.remindMeOnDate ? moment(this.state.remindMeOnDate).format("DD-MM-YYYY") : null;
  const remindMeOnHour = this.state.remindMeOnHour ? moment(this.state.remindMeOnHour).format("HH:mm") : null;
  const body = {
    category_id:this.state.catagory,
    work_needed: this.state.workNeeded,
    due_date: dueDate,
    due_date_time: dueHour,
    due_date_frequency: this.state.dueDateFrequency,
    remind_me: remindMeOnDate,
    remind_me_time: remindMeOnHour,
    remind_me_frequency: this.state.remindMeFrequency,
    availability: this.state.availabilityState,
    description: this.state.description,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.addServiceApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addServiceApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.exampleAPiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);  
}
navigateToAppointments = () => {
  const navigationMessage = new Message(
    getName(MessageEnum.NavigationMessage)
  );
  navigationMessage.addData(
    getName(MessageEnum.NavigationTargetMessage),
    "ServicerequestDashboard"
  );
  navigationMessage.addData(
    getName(MessageEnum.NavigationPropsMessage),
    this.props
  );
  runEngine.sendMessage("MergeEngineUtilities", navigationMessage);
};
setCatagory = (id: number) => {
  this.setState({
    catagory: id,
  });
};

setDueFrequency = (frequency: string) => {
  this.setState({
    dueDateFrequency: frequency,
  });
};

setRemindMeFrequency = (frequency: string) => {
  this.setState({
    remindMeFrequency: frequency,
  })}
  handlePopupMessageClose = () => {
    this.setState({
      messageOpen: false,
    });
  };
  // Customizable Area End
}
